import { isEqual } from 'lodash';
import { setCompanyTemplates, upsertCompanyTemplate, removeCompanyTemplate } from '../store/companyTemplates';
import { setGlobalTemplates } from '../store/globalTemplates';
import { addStatus, updateStatus } from '../store/fileUploadStatus';
import DocumentsService from './DocumentsService';
import { setCurrentCompany } from '../store/company';
import { setCompanyUsersList } from '../store/companyUsers';
import { allowUserToUpdateTemplate } from '../store/selectors';
import Service from './Service';

class TemplateService extends Service {
  constructor(apiClient, store) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
    this.uploadId = 0;
  }

  isUserWithTemplateAccess() {
    return allowUserToUpdateTemplate(this.store.getState());
  }

  getResourceUrl(companyUUID) {
    return `/companies/${companyUUID}/templates`;
  }

  async loadGlobalTemplate() {
    const response = await this.apiClient.get('/admin/templateMappings/globalTemplates');
    const { response: { templates } } = response;
    this.store.dispatch(setGlobalTemplates(templates));
    return templates;
  }

  async loadCompanyTemplatesList(companyUUID) {
    const result = await this.apiClient.get(
      this.getResourceUrl(companyUUID),
    );

    const companyTemplateList = result.response.templates;
    this.store.dispatch(setCompanyTemplates(companyTemplateList));
    this.store.dispatch(setCurrentCompany(companyUUID));
    return companyTemplateList;
  }

  async loadCompanyUsersList(companyUUID) {
    const result = await this.apiClient.get(
      `/companies/${companyUUID}/users`,
    );

    const companyUsersList = result.response.users;
    this.store.dispatch(setCompanyUsersList(companyUsersList));
    this.store.dispatch(setCurrentCompany(companyUUID));
    return companyUsersList;
  }

  async cloneTemplate(companyUUID, templateForm) {
    const formData = new FormData();
    formData.append('template', templateForm.templateFile);
    formData.append('templateName', templateForm.templateName);
    formData.append('tag', templateForm.tag);
    formData.append('isReportEnabled', templateForm.isReportEnabled);
    formData.append('allowAll', templateForm.allowAll);
    templateForm.assetTypes.forEach((assetType) => {
      formData.append('assetTypes[]', assetType);
    });
    if (templateForm.keysToMask.length > 0) {
      templateForm.keysToMask.forEach((key) => {
        formData.append('keysToMask[]', key);
      });
    }
    if (templateForm.dictionaryFile !== null) {
      formData.append('dictionary', templateForm.dictionaryFile);
    }
    if (templateForm.categorySequenceFile !== null) {
      formData.append('categorySequence', templateForm.categorySequenceFile);
    }
    if (templateForm.summarySheetTotalConfigFile !== null) {
      formData.append('summarySheetTotalConfig', templateForm.summarySheetTotalConfigFile);
    }
    const url = `/admin${this.getResourceUrl(companyUUID)}`;
    const result = await this.apiClient.post(
      url,
      formData,
    ).catch((e) => {
      throw e;
    });
    const companyTemplate = result.response.template;
    return companyTemplate
  }

  async uploadTemplate(companyUUID, templateForm) {
    const uploadId = this.uploadId++;
    this.store.dispatch(
      addStatus(
        uploadId,
        templateForm.templateFile.name,
        DocumentsService.getDocumentTypeFromName(templateForm.templateFile.name)
      )
    );
    const formData = new FormData();
    formData.append('template', templateForm.templateFile);
    formData.append('templateName', templateForm.templateName);
    formData.append('tag', templateForm.tag);
    formData.append('isReportEnabled', templateForm.isReportEnabled === true);
    formData.append('allowAll', templateForm.allowAll);
    formData.append('allowedUsers', JSON.stringify(templateForm.allowedUsers))
    templateForm.assetTypes.forEach((assetType) => {
      formData.append('assetTypes[]', assetType);
    });
    if (templateForm.keysToMask.length > 0) {
      templateForm.keysToMask.forEach((key) => {
        formData.append('keysToMask[]', key);
      });
    }
    if (templateForm.dictionaryFile !== null) {
      formData.append('dictionary', templateForm.dictionaryFile);
    }
    if (templateForm.categorySequenceFile !== null) {
      formData.append('categorySequence', templateForm.categorySequenceFile);
    }
    if (templateForm.summarySheetTotalConfigFile !== null) {
      formData.append('summarySheetTotalConfig', templateForm.summarySheetTotalConfigFile);
    }
    const url = this.isUserWithTemplateAccess() ? this.getResourceUrl(companyUUID) : `/admin${this.getResourceUrl(companyUUID)}`;
    const result = await this.apiClient.post(
      url,
      formData,
    ).catch((e) => {
      this.store.dispatch(updateStatus(uploadId, 'failed'));
      throw e;
    });

    this.store.dispatch(updateStatus(uploadId, 'success'));
    const companyTemplate = result.response.template;
    this.store.dispatch(upsertCompanyTemplate(companyTemplate));
    await this.loadCompanyUsersList(companyUUID);
    return companyTemplate;
  }

  async uploadGlobalTemplate(templateForm) {
    const uploadId = this.uploadId++;
    this.store.dispatch(
      addStatus(
        uploadId,
        templateForm.templateFile.name,
        DocumentsService.getDocumentTypeFromName(templateForm.templateFile.name)
      )
    );
    const formData = new FormData();
    formData.append('template', templateForm.templateFile);
    formData.append('templateName', templateForm.templateName);
    formData.append('tag', templateForm.tag);
    formData.append('isReportEnabled', templateForm.isReportEnabled === true);
    templateForm.assetTypes.forEach((assetType) => {
      formData.append('assetTypes[]', assetType);
    });
    if (templateForm.keysToMask.length > 0) {
      templateForm.keysToMask.forEach((key) => {
        formData.append('keysToMask[]', key);
      });
    }
    if (templateForm.dictionaryFile !== null) {
      formData.append('dictionary', templateForm.dictionaryFile);
    }
    if (templateForm.categorySequenceFile !== null) {
      formData.append('categorySequence', templateForm.categorySequenceFile);
    }
    const result = await this.apiClient.post(
      '/admin/templateMappings',
      formData,
    ).catch((e) => {
      this.store.dispatch(updateStatus(uploadId, 'failed'));
      throw e;
    });

    this.store.dispatch(updateStatus(uploadId, 'success'));
    const companyTemplate = result.response.template;
    await this.loadGlobalTemplate();
    return companyTemplate;
  }

  async deleteTemplate(companyUUID, template) {
    const url = this.isUserWithTemplateAccess() ? `/companies/${companyUUID}/templates/${template.uuid}` : `/admin/companies/${companyUUID}/templates/${template.uuid}`;

    await this.apiClient.delete(url);
    this.store.dispatch(removeCompanyTemplate(template));
  }

  async updateTemplate(companyUUID, template, templateForm) {    
    const uploadId = this.uploadId++;
    this.store.dispatch(addStatus(uploadId, template.templateName, 'UNKNOWN'));
    const formData = new FormData();
    if (templateForm.mappings !== null) {
      formData.append('template', templateForm.mappings);
    }
    if (templateForm.dictionary !== null) {
      formData.append('dictionary', templateForm.dictionary);
    }
    if (templateForm.categorySequence !== null) {
      formData.append('categorySequence', templateForm.categorySequence);
    }
    if (templateForm.summarySheetTotalConfig !== null) {
      formData.append('summarySheetTotalConfig', templateForm.summarySheetTotalConfig);
    }
    if (templateForm.templateName !== template.templateName) {
      formData.append('templateName', templateForm.templateName);
    }
    if (templateForm.tag !== template.tag) {
      formData.append('tag', templateForm.tag);
    }
    if (templateForm.isReportEnabled !== template.isReportEnabled) {
      formData.append('isReportEnabled', templateForm.isReportEnabled === true);
    }

    formData.append('allowAll', templateForm.allowAll);
    formData.append('allowedUsers', JSON.stringify(templateForm.allowedUsers))

    if (templateForm.assetTypes) {
      if (!isEqual(templateForm.assetTypes.sort(), template.assetTypes.sort())) {
        templateForm.assetTypes.forEach((assetType) => {
          formData.append('assetTypes[]', assetType);
        });
      }
    }

    if (templateForm.allowAll) {
      formData.append('allowAll', `${templateForm?.allowAll}`);
    }

    if (!isEqual(templateForm.keysToMask.sort(), template.keysToMask.sort())) {
      templateForm.keysToMask.length > 0 ? templateForm.keysToMask.forEach((key) => {
        formData.append('keysToMask[]', key);
      }) : formData.append('keysToMask', JSON.stringify([]));
    }
    const url = this.isUserWithTemplateAccess() ? `/companies/${companyUUID}/templates/${template.uuid}` : `/admin/companies/${companyUUID}/templates/${template.uuid}`;
    await this.apiClient.put(
      url,
      formData,
    ).catch((e) => {
      this.store.dispatch(updateStatus(uploadId, 'failed'));
      throw e;
    });
    this.store.dispatch(upsertCompanyTemplate({
      ...template,
      templateName: templateForm.templateName,
      tag: templateForm.tag,
      assetTypes: templateForm.assetTypes,
      keysToMask: templateForm.keysToMask
    }));
    this.store.dispatch(updateStatus(uploadId, 'success'));
    this.loadCompanyTemplatesList(companyUUID);
    await this.loadCompanyUsersList(companyUUID);
  }

  async updateGlobalTemplate(template, templateForm) {
    const uploadId = this.uploadId++;
    this.store.dispatch(addStatus(uploadId, template.templateName, 'UNKNOWN'));
    const formData = new FormData();
    if (templateForm.mappings !== null) {
      formData.append('template', templateForm.mappings);
    }
    if (templateForm.dictionary !== null) {
      formData.append('dictionary', templateForm.dictionary);
    }
    if (templateForm.categorySequence !== null) {
      formData.append('categorySequence', templateForm.categorySequence);
    }
    if (templateForm.summarySheetTotalConfig !== null) {
      formData.append('summarySheetTotalConfig', templateForm.summarySheetTotalConfig);
    }
    if (templateForm.templateName !== template.templateName) {
      formData.append('templateName', templateForm.templateName);
    }
    if (templateForm.tag !== template.tag) {
      formData.append('tag', templateForm.tag);
    }
    if (templateForm.isReportEnabled !== template.isReportEnabled) {
      formData.append('isReportEnabled', templateForm.isReportEnabled === true);
    }

    if (templateForm.assetTypes) {
      if (!isEqual(templateForm.assetTypes.sort(), template.assetTypes ? template.assetTypes.sort(): [])) {
        templateForm.assetTypes.forEach((assetType) => {
          formData.append('assetTypes[]', assetType);
        });
      }
    }

    if (!isEqual(templateForm.keysToMask.sort(), template.keysToMask.sort())) {
      templateForm.keysToMask.length > 0 ? templateForm.keysToMask.forEach((key) => {
        formData.append('keysToMask[]', key);
      }) : formData.append('keysToMask', JSON.stringify([]));
    }

    const url = `/admin/templateMappings/${template.uuid}`;
    await this.apiClient.put(
      url,
      formData,
    ).catch((e) => {
      this.store.dispatch(updateStatus(uploadId, 'failed'));
      throw e;
    });
    this.loadGlobalTemplate();
    this.store.dispatch(updateStatus(uploadId, 'success'));
  }

  getDownloadCsvOrJsonUrl(url, isDownloadCSV) {
    return isDownloadCSV ? this.getUrlWithQueryParams(url, { fileType: 'csv' }) : url;
  }

  async downloadTemplate(companyUuid, template, isDownloadCSV = false) {
    const url = this.getDownloadCsvOrJsonUrl(
      `/admin/companies/${companyUuid}/templates/${template.uuid}/file`,
      isDownloadCSV
    );
    const downloadFileFormatExtension = isDownloadCSV ? 'csv' : 'json';
    return this.downloadFile(url, `${template.templateName}.${downloadFileFormatExtension}`);
  }

  async getTemplateFile(companyUuid, templateUuid) {
    return await this.apiClient.get(
      `/admin/companies/${companyUuid}/templates/${templateUuid}/file`, { responseType: 'blob' },
    );
  }

  async getDictionaryFile(templateUuid) {
    return await this.apiClient.get(
      `/admin/templateMappings/${templateUuid}/dictionary`, { responseType: 'blob' },
    );
  }

  async getCategorySequenceFile(templateUuid) {
    return await this.apiClient.get(
      `/admin/templateMappings/${templateUuid}/categorySequence`, { responseType: 'blob' },
    );
  }

  async getSummaryTotalConfigFile(templateUuid) {
    return await this.apiClient.get(
      `/admin/templateMappings/${templateUuid}/summaryTotalConfig`, { responseType: 'blob' },
    );
  }
  
  async downloadGlobalTemplate(template, isDownloadCSV = false) {
    const url = this.getDownloadCsvOrJsonUrl(
      `/admin/templateMappings/${template.uuid}/file`,
      isDownloadCSV
    );
    const downloadFileFormatExtension = isDownloadCSV ? 'csv' : 'json';
    return this.downloadFile(url, `${template.templateName}.${downloadFileFormatExtension}`);
  }

  async downloadDictionary(template, isDownloadCSV = false) {
    const url = this.getDownloadCsvOrJsonUrl(
      `/admin/templateMappings/${template.uuid}/dictionary`,
      isDownloadCSV
    );
    const downloadFileFormatExtension = isDownloadCSV ? 'csv' : 'json';
    return this.downloadFile(url, `${template.templateName}-dictionary.${downloadFileFormatExtension}`);
  }

  async downloadCategorySequence(template, isDownloadCSV = false) {
    const url = this.getDownloadCsvOrJsonUrl(
      `/admin/templateMappings/${template.uuid}/categorySequence`,
      isDownloadCSV
    );
    const downloadFileFormatExtension = isDownloadCSV ? 'csv' : 'json';
    return this.downloadFile(url, `${template.templateName}-category-sequence.${downloadFileFormatExtension}`);
  }

  async downloadSummaryTotalConfig(template, isDownloadCSV = false) {
    const url = this.getDownloadCsvOrJsonUrl(
      `/admin/templateMappings/${template.uuid}/summaryTotalConfig`,
      isDownloadCSV
    );
    const downloadFileFormatExtension = isDownloadCSV ? 'csv' : 'json';
    return this.downloadFile(url, `${template.templateName}-summary-total-config.${downloadFileFormatExtension}`);
  }
}

export default TemplateService;
