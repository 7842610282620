import React, { useCallback, useMemo, useState } from 'react'
import { withServices } from 'reaf';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormModal from '../FormModal/FormModal'
import BulkProjectUploadComponent from '../BulkProjectUpload/BulkProjectUploadComponent'
import useModalForm from '../../../hooks/useModalForm';
import config from '../../../config';
import messages from '../../../../locales/en-US';

function BulkUpdateProjectUpload({ onHide, showModal, companyService, projectsService, refreshData, toastrService }) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [projectFile, setProjectFile] = useState(null);
  const [companies, setCompanies] = useState([]);

  const loadAllCompanies = useCallback(async () => {
    const companies = await companyService.getAllCompanies();
    setCompanies(companies.rows);
  }, [])

  React.useEffect(() => {
    loadAllCompanies();
  }, [])

  const initialValues = {
    companyUuid: '',
    sendLogTo: config.app.sendLogEmail
  }

  const bulkProjectUploadValidationSchema = useMemo(() => Yup.object().shape({
    companyUuid: Yup.string().required('Please select company'),
    sendLogTo: Yup.string().email('Please enter a valid email').required('Please enter email id to receive log')
  }), []);

  async function onSubmitForm(values, actions) {
    actions.setSubmitting(true);
    try {
      const payload = {
        ...values,
        file: projectFile
      }
      await projectsService.bulkUpdateProjects(payload);
      refreshData();
      toastrService.success(messages.toastMessage.MIGRATE_PROJECT_SUCCESS, null, 5000);
      onHide();
    }  catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <FormModal
      isSubmitting={isSubmitting} 
      onSubmit={onSubmit}
      onHide={onHide}
      show={showModal} size="lg"
      title="Bulk Update Project Upload">
      <Formik
        initialValues={initialValues}
        validationSchema={bulkProjectUploadValidationSchema}
        onSubmit={onSubmitForm}
        component={formikProps => (
          <BulkProjectUploadComponent
            {...formikProps}
            bindSubmission={bindSubmission}
            companies={companies}
            selectedProjectFile={projectFile}
            projectFileName="projectFile"
            onHide={onHide}
            onProjectFileChange={e => setProjectFile(e.target.files[0])}
            onRemoveProjectFile={() => setProjectFile(null)}
          />
        )
        }
      />
    </FormModal>
  )
}

export default withServices('companyService', 'projectsService', 'toastrService')(BulkUpdateProjectUpload);

