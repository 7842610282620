import React, { useCallback } from 'react';
import { withServices } from 'reaf';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import DisplayAssetType from '../DisplayAssetType/DisplayAssetType';
import { CompanyTemplatesPageLink, ProjectDocumentsPageLink } from '../../core/adminLinks/links';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import EditProject from '../EditProject/EditProject';
import EditDictionary from '../EditDictionary/EditDictionary';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import BulkProjectUpload from '../BulkProjectUpload/BulkProjectUpload';
import BulkUpdateProjectUpload from '../BulkUpdateProjectUpload/BulkUpdateProjectUpload';
import BulkProjectJson from '../BulkProjectJson/BulkProjectJson';
import { projectActionValues } from '../../../constants';
import messages from '../../../../locales';


const ActionButton = React.forwardRef(({ children, onClick }, ref) => {
  const onActionButtonClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    },
    [onClick]
  );

  return (
    <div ref={ref} onClick={onActionButtonClicked}>
      {children}
    </div>
  );
});

const ProjectList = ({ queries, apiClient, router, projectsService, toastrService }) => {
  const [showBulkProjectUpload, setShowBulkProjectUpload] = React.useState(false);
  const [showBulkProjectUpdate, setShowBulkProjectUpdate] = React.useState(false);
  const [showBulkProjectJson, setShowBulkProjectJson] = React.useState(false);
  const [currentProjectAction, setCurrentProjectAction] = React.useState(projectActionValues[0])
  const {
    data: projects,
    loading,
    pageCount,
    setData: setProjects,
    onDownloadCSV,
    refreshData,
    fetchData: fetchProjects,
  } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: "superAdminProjectPage",
    resourceEndPoint: "/admin/projects",
    csvDownloadEndPoint: "/admin/projects/downloadCSV",
  });

  const deleteCompletedModel = async(project) => {
    await projectsService.deleteCompletedProjectModel(project);
    toastrService.success(messages.toastMessage.COMPLETED_MODEL_DELETE_SUCCESS);
  }
  const downloadCompletedModel = async(project) => {
    await projectsService.getCompletedModel(project);
    toastrService.success(messages.toastMessage.COMPLETED_MODEL_DOWNLOAD_SUCCESS);
  }

  const updateProject = React.useCallback((updatedProject) => {
    const index = projects.findIndex(project => project.uuid === updatedProject.uuid);
    const updateProjectsArray = [...projects];
    updateProjectsArray[index] = updatedProject;
    setProjects(updateProjectsArray);
  }, [projects]);

  const onSelectProjectAction = React.useCallback((action) => {
    switch (action.value) {
      case 'showBulkProjectUpload':
        setShowBulkProjectUpload(true);
        break;
      case 'showBulkProjectUpdate':
        setShowBulkProjectUpdate(true);
        break;
      case 'showBulkProjectJson':
        setShowBulkProjectJson(true);
        break;
      default:
        break;
    }
  }, [showBulkProjectUpload, showBulkProjectJson])

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      fixedWidth: 200
    },
    {
      Header: 'Owner',
      accessor: 'Users.firstName',
      fixedWidth: 160,
      Cell: ({ row: { original: { Users } } }) => (Users && Users[0] ? <WithTooltip tooltipText={Users[0].email}><span>{`${Users[0].firstName} ${Users[0].lastName}`}</span></WithTooltip> : 'Deleted')
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 180,
      Cell: ({ row: { original: { Company } } }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'Asset',
      accessor: 'assetType',
      fixedWidth: 160,
      Cell: ({ value: assetType }) => <DisplayAssetType AssetType={assetType} />
    },
    {
      Header: 'Template',
      fixedWidth: 180,
      accessor: 'TemplateMapping.templateName',
      Cell: ({ row }) => <CompanyTemplatesPageLink 
        text={row.original.TemplateMapping.templateName} 
        companyUUID={row.original.Company.uuid} 
      />
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      disableFilters: true,
      Cell: ({ value }) => <DisplayDate date={value} />
    },
    {
      Header: 'Actions',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      ignoreTextEllipsis: true,
      Cell: ({ row: { original: project } }) => (
        <Dropdown>
          <Dropdown.Toggle as={ActionButton}>
              <IconButton
                variant="outline-secondary"
                className="smallIconButton actionButton"
                icon={faEllipsisV}
              />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <ProjectDocumentsPageLink id="listDocument" title="List Documents" text="Documents" project={project} hideIcon />
            </Dropdown.Item>
            <Dropdown.Item>
              <EditProject 
                onEditSuccess={(updatedProject) => updateProject(updatedProject)} 
                project={project} 
                label="Edit Project" 
                company={project.Company}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <EditDictionary onEditSuccess={noop} project={project} hideIcon  />
            </Dropdown.Item>
            {!!project.completedModel && <>
              <Dropdown.Item onClick={() => deleteCompletedModel(project)}>
                Delete completed model
              </Dropdown.Item>
              <Dropdown.Item onClick={() => downloadCompletedModel(project)}>
                Download completed model
              </Dropdown.Item>
            </>}
          </Dropdown.Menu>
          
        </Dropdown>
      ),
    },
  ], [projects, updateProject]);

  return (
    <>
      {
        showBulkProjectUpload &&
        <BulkProjectUpload
          showModal={showBulkProjectUpload}
          onHide={() => {
            setShowBulkProjectUpload(false)
            setCurrentProjectAction(projectActionValues[0]);
          }}
          refreshData={refreshData}
        />
      }
      {
        showBulkProjectUpdate &&
        <BulkUpdateProjectUpload
          showModal={showBulkProjectUpdate}
          onHide={() => setShowBulkProjectUpdate(false)}
          refreshData={refreshData}
        />
      }
      {
        showBulkProjectJson && 
        <BulkProjectJson 
          showModal={showBulkProjectJson}
          projects={projects}
          onHide={() => setShowBulkProjectJson(false)}
          refreshData={refreshData}
        />
      }
      <ReactTable
        columns={columns}
        data={projects}
        title="Projects"
        onSelectProjectAction={onSelectProjectAction}
        projectActionValues={projectActionValues}
        currentProjectAction={currentProjectAction}
        onDownloadCSV={onDownloadCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchProjects}
        pageCount={pageCount}
      />
    </>
  );
};

export default withServices('apiClient', 'router', 'projectsService', 'toastrService')(ProjectList);
