import React from 'react';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import Link from '../Link/Link';
import IconButton from '../Button/IconButton';

function ProjectDocumentsPageLink({ title, project, hideIcon=false, route = 'superAdminDocumentsPage' }) {
  return (
    <Link
      route={route}
      params={{ _search: { 'Project.name': project.name } }}
    >
      {hideIcon ? <div className="text-dark">{title}</div> :  <IconButton
        className="smallIconButton"
        placement="left"
        title={title}
        icon={faFile}
      />}
    </Link>
  );
}

function CompanyTemplatesPageLink({ text, companyUUID }) {
  return (
    <Link route="superAdminTemplateManagement" params={{ companyUUID }}>
      {text}
    </Link>
  );
}

function CompanyLink({ text }) {
  return <Link route="superAdminPage">{text}</Link>;
}

function ProjectLink({ text, projectUuid }) {
  return (
    <Link route="superAdminProjectPage" params={{ projectUuid }}>
      {text}
    </Link>
  );
}

function ProjectExporterPageLink({ text, companyUUID, style, className }) {
  return (
    <Link
      route="superAdminCompanyProjectExporters"
      params={{ companyUUID }}
      style={style}
      className={className}
    >
      {text}
    </Link>
  );
}

export {
  ProjectDocumentsPageLink,
  CompanyTemplatesPageLink,
  CompanyLink,
  ProjectLink,
  ProjectExporterPageLink
};
