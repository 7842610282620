import React from 'react';
import { Provider } from 'react-redux';
import { withServices } from 'reaf';
import ClearCache from 'react-clear-cache';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './App.css';
import './app/assets/scss/variables.scss';
import './app/assets/scss/subheader.scss';
import './app/assets/scss/tables.scss';
import './app/assets/scss/forms.scss';
import './app/assets/scss/button.scss';
import './app/assets/scss/misc.scss';
import './app/assets/scss/card.scss';
import './app/assets/scss/modal.scss';
import './app/assets/scss/bootstrap.scss';
import './app/assets/scss/authstyle.scss';
import './app/assets/scss/superadmin.scss';
import LoadingBackdrop from './app/components/app/LoadingBackdrop/LoadingBackdrop';
import NotificationToastr from './app/components/app/NotificationToastr/NotificationToastr';
import RibbonNotification from './app/components/app/RibbonNotification/RibbonNotification';
import FeatureFlagProvider from './app/providers/FeatureFlagProvider';
import { appConfig } from './app/config';

function App({ children, store }) {
  return (
    <ClearCache auto duration={5000} basePath={appConfig.completeUrl}>
      {() => (
        <Provider store={store}>
          <FeatureFlagProvider>
            <div className="App overflow-hidden">
              <RibbonNotification />
              {children}
              <LoadingBackdrop />
              <NotificationToastr />
            </div>
          </FeatureFlagProvider>
        </Provider>
      )}
    </ClearCache>
  );
}

export default withServices('store')(App);
