import { sortBy } from 'lodash';
import Cookie from 'universal-cookie';
import { setCompanyUsersList, addUserInCompanyUsersList, removeUserFromCompanyUsersList } from '../store/companyUsers';
import { setCurrentCompany } from '../store/company';
import { setUserAllowedTemplates } from '../store/auth';
import { appConfig } from '../config';

const CG_SUPER_ADMIN_AUTH_TOKEN = 'USER_SESSION_superAdmin'

class UserService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
    this.cookie = new Cookie();
  }

  getSuperAdminCookie() {
    return this.cookie.get(CG_SUPER_ADMIN_AUTH_TOKEN)
  }

  async loadCompanyUsersList(companyUUID) {
    const result = await this.apiClient.get(
      `/companies/${companyUUID}/users`,
    );

    const companyUsersList = result.response.users;
    this.store.dispatch(setCompanyUsersList(companyUsersList));
    this.store.dispatch(setCurrentCompany(companyUUID));
    return companyUsersList;
  }

  async loadUserAllowedTemplates() {
    const result = await this.apiClient.get(
      '/users/templateAccess',
    );
    const { allowedTemplates } = result.response;
    const sortedTemplates = sortBy(allowedTemplates, [template => template.templateName.toLowerCase()]);
    this.store.dispatch(setUserAllowedTemplates(sortedTemplates));
    return allowedTemplates;
  }

  async createCompanyUser(values, companyUUID) {
    const cgAdminCookie = this.getSuperAdminCookie();
    
    const result = await this.apiClient.post(
      `/companies/${companyUUID}/users`,
      {...values, cgAdminCookie}
    );

    const { user } = result.response;
    this.store.dispatch(addUserInCompanyUsersList(user));

    return result.response;
  }

  async deleteCompanyUser(companyUser, companyUuid) {
    const result = await this.apiClient.delete(
      `/companies/${companyUuid}/users/${companyUser.uuid}`,
    );

    this.store.dispatch(removeUserFromCompanyUsersList(companyUser));

    return result.response;
  }


  async deleteCompanyAndCGUser(companyUser, companyUuid) {
    const cgAdminCookie = this.getSuperAdminCookie();
    const result = await this.apiClient.delete(
      `/companies/${companyUuid}/users/${companyUser.uuid}/${companyUser.cgUuid}`, {
        cgAdminCookie
      }
    );

    this.store.dispatch(removeUserFromCompanyUsersList(companyUser));

    return result.response;
  }

  async userResetPassword(user, newPassword) {
    const result = await this.apiClient.put(
      `/admin/users/${user.uuid}/resetPassword`,
      {
        newPassword
      }
    );
    return result.response;
  }

  async updateCompanyUser(values) {
    const result = await this.apiClient.put(
      `/admin/users/${values.user.uuid}`,
      values,
    );

    const { user } = result.response;
    this.store.dispatch(addUserInCompanyUsersList(user));

    return result.response;
  }

  async toggleAccess(user) {
    const result = await this.apiClient.put(
      `/admin/users/${user.uuid}`,
      { user: { isActive: !user.isActive } },
    );

    const { user: updatedUser } = result.response;
    this.store.dispatch(addUserInCompanyUsersList(updatedUser));

    return result.response;
  }

  async toggleApiConfigAccess(user) {
    const result = await this.apiClient.put(
      `/admin/users/${user.uuid}`,
      { user: { apiConfigAccess: !user.apiConfigAccess } },
    );

    const { user: updatedUser } = result.response;
    this.store.dispatch(addUserInCompanyUsersList(updatedUser));

    return result.response;
  }

  async updateTemplateAccess(readAccess, writeAccess, userUuid) {
    return this.apiClient.post(
      `/admin/users/${userUuid}/templateAccess`,
      {
        readAccess, 
        writeAccess
      },
    );
  }

  async getTemplateAccess(userUuid) {
    const result = await this.apiClient.get(
      `/admin/users/${userUuid}/templateAccess`
    );
    return result.response.allowedTemplates;
  }
}

export default UserService;
