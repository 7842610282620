import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { withServices } from 'reaf';
import uploadDocIcon from '../../../assets/images/upload-doc.svg';
import style from './UploadCompletedModelForm.module.scss';

function UploadCompletedModelForm({
  project,
  documentsService,
  BrowseButton,
  onSubmit,
  toastrService,
  eventTrackerService,
  templateModelService,
  projectsService,
  company,
  ...restProps
}) {
  const fileInput = useRef();
  const formRef = useRef();

  async function onFileSelect() {
    const { files } = fileInput.current;
  
    try {
      await templateModelService.uploadCompletedModel(company, project, files);
      await projectsService.loadCurrentProject(project.uuid);
      toastrService.success('Uploaded successfully!');
    } catch (e) {
      toastrService.error(e.message);
      console.log(e);
    }
    onSubmit && onSubmit();
    fileInput.current.value = null;
  }
  

  function submitForm(event) {
    event.preventDefault();
    const { files } = fileInput.current;
    templateModelService.uploadCompletedModel(project, files).catch((e) => {
      toastrService.error(e.message);
      console.log(e);
    });
  }
  return (
    <form ref={formRef} onSubmit={submitForm}>
      <div className="form-group m-0">
        {BrowseButton ? (
          <BrowseButton
            onClick={() => {
              fileInput.current.click();
            }}
            {...restProps}
          />
        ) : (
          <Button
            variant="light"
            className={`btn-sm ${style.browseBtn}`}
            type="button"
            onClick={() => {
              fileInput.current.click();
            }}
            {...restProps}
          >
            <img src={uploadDocIcon} alt="upload doc" />
            {project.completedModel ? 'Update Completed Model' : 'Upload Completed Model'}
          </Button>
        )}
        <input
          multiple
          type="file"
          name="file"
          hidden
          ref={fileInput}
          onChange={() => onFileSelect()}
        />
      </div>
    </form>
  );
}

export default withServices(
  'documentsService',
  'toastrService',
  'eventTrackerService',
  'templateModelService',
  'projectsService'
)(UploadCompletedModelForm);
