import React, { useState, useEffect, useCallback, useMemo } from 'react';
import lodash, { filter, isEqual } from 'lodash';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { faTag as tagIcon } from '@fortawesome/free-solid-svg-icons/faTag';
import {
  authSelector,
  loggedInCompanySelector,
  companyTemplatesSelector,
  currentProjectDocumentsAsListSelector,
  documentsSummarySelector,
  currentProjectSelector,
  companyTemplateModelSelector,
  dynamicAssetTypesListSelector,
  isConsolidatedModelDownloadingSelector,
  downloadFileNameSelector,
  readOnlyAccessSelector
} from '../../../store/selectors';
import { setApiConfigIntegration } from '../../../store/currentDocument';
import { appConfig } from '../../../config';
import AddDocumentForm from '../../app/AddDocumentForm/AddDocumentForm';
import UploadCompletedModelForm from '../../app/UploadCompletedModelForm/UploadCompletedModelForm';
import DocumentsView from '../../app/DocumentsView/DocumentsView';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import DocumentFileDrop from '../../app/DocumentFileDrop/DocumentFileDrop';
import UploadProgress from '../../app/UploadProgress/UploadProgress';
import DownloadProgress from '../../app/DownloadProgress/DownloadProgress';
import {
  ExtractionJobStatus,
  FILE_EXT_TYPES
} from '../../../constants';
import ProjectPageHeader from './ProjectPageHeader';

import ProjectPageTour from './ProjectPageTour';
import AddFileTour from './AddFileTour';
import { markFirstTourDone } from '../../../store/productTour';
import style from './ProjectPage.module.scss';
import DocumentPageTour from '../DocumentPage/DocumentPageTour';
import DocumentsService from '../../../services/DocumentsService';
import { setCurrentActiveViewerSheet, setIsConsolidatedModelDownloading } from '../../../store/currentProject';
import DocumentUploadErrorPopup from '../../app/DocumentUploadErrorPopup/DocumentUploadErrorPopup';
import ProjectNote from './ProjectNote';
import { useFeatureFlag } from '../../../providers/FeatureFlagProvider';

const ProjectPageSubHeader = ({ project, template, onFileUpload, assetTypes, readOnlyAccess, company, authService, completedModelDownloadEnabled }) => {
  const assetTypesMap = useMemo(() => new Map(assetTypes.map(assetType => [assetType.assetType, assetType])));
  return (
    <Container fluid className="bg-white subHeader-ms">
      <Row className="align-items-center">
        <Col sm="auto">
          <h5 className="mb-0">{`${project.name} - Documents`}</h5>
        </Col>
        <Col className="pl-0">
          <Badge
            variant={`pill badge-info mr-2 ${style.badgeOutlineInfo}`}
            icon={tagIcon}
          >
            {assetTypesMap.has(project.assetType) && assetTypesMap.get(project.assetType).label}
          </Badge>
          <Badge variant={`pill badge-info ${style.badgeOutlineInfo}`}>
            {template.templateName}
          </Badge>
        </Col>
        {completedModelDownloadEnabled && authService.isSupportUser() && <Col sm="auto">
          <UploadCompletedModelForm
            id="BrowseFileButton"
            project={project}
            onSubmit={onFileUpload}
            company={company}
          />
        </Col>}
        {!readOnlyAccess && (
        <Col sm="auto">
          <AddDocumentForm
            id="BrowseFileButton"
            project={project}
            onSubmit={onFileUpload}
          />
        </Col>
        )}
      </Row>
    </Container>
  );
};

function ProjectPage({
  project,
  documents,
  company,
  projectsService,
  template,
  startTour,
  store,
  documentsSummary,
  templateModels,
  projectExporters,
  assetTypes,
  companyService,
  isConsolidatedModelDownloading,
  downloadFileName,
  readOnlyAccess,
  authService
}) {
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const [searchText, setSearchText] = useState('');
  const [documentTypeFilter, setDocumentTypeFilter] = useState('');
  const [showTour, setShowTour] = useState(startTour);
  const [showAddFileTutorial, setShowAddFileTutorial] = useState(false);
  const [showTaggingFormTutorial, setShowTaggingFormTutorial] = useState(false);
  const [tourStep, setTourStep] = useState(undefined);
  const [showTaggingForm, setShowTaggingForm] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentsForModel, setSelectedDocumentsForModel] = useState(null);
  const [documentsUuidsForModel, setDocumentsUuidsForModel] = useState([]);
  const [succesfulTaggedDocuments, setSuccessfulTaggedDocuments] = useState([]);
  const [completedModelDownloadEnabled] = useFeatureFlag('completedModelDownload', false);

  const selectedModelForReportGeneration = useMemo(
    () => template.isReportEnabled  ? lodash.findLast(templateModels || [], { hasReportTemplateFile: true }) : null,
    [templateModels, template]
  );

  useEffect(() => {
    const loadApiConfiguration = async(company) => {
      const apiConfig = await companyService.getApiConfiguraion(company.uuid);
      store.dispatch(setApiConfigIntegration(apiConfig?.apiIntegrationConfiguration[0]));
    }
    company && loadApiConfiguration(company);
    projectsService.fetchAssumption(project)
  }, [company]);

  let enableProjectCompletion = true;
  if (documentsSummary) {
    const projectDoc = documentsSummary[project.uuid]
      ? documentsSummary[project.uuid]
      : {};
    enableProjectCompletion =
      (!!projectDoc.tagged ||
        !!projectDoc.converted ||
        !!projectDoc.extracted) &&
      !appConfig.isFreebieApp;
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (projectsService && project && project.uuid) {
        await projectsService.loadCurrentProjectDocuments(project.uuid);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [projectsService, project]);

  useEffect(() => {
    setSelectedDocument(null);
  }, [searchText, documentTypeFilter]);


  useEffect(() => {
      const uuids = [];
      Array.isArray(selectedDocumentsForModel) && selectedDocumentsForModel.map(doc => {
        if(doc.children) {
          doc.children.map(child => {
            if(child.extractionJob && child.isValidated) {
              uuids.push(child.uuid);
            }
          })
        }
      })
      setDocumentsUuidsForModel([...uuids]);
  }, [selectedDocumentsForModel])

  useEffect(() => {
    const oldTaggedDocuments = documents
      .filter(
        (doc) => !doc.groupId && doc.taggingData && doc.taggingData.documentType
      )
      .map((doc) => ({ ...doc, groupId: doc.uuid }));

    const docs = Object.values(
      [...documents, ...oldTaggedDocuments].reduce((docCollection, doc) => {
        if (!doc.groupId) {
          docCollection[doc.uuid] = { ...docCollection[doc.uuid], ...doc };
        }

        if (doc.groupId) {
          let grpId = doc.groupId;
          let groupObj = docCollection[grpId] || {};
          if (grpId === doc.uuid) {
            groupObj = { ...doc, ...groupObj };
          }

          groupObj.children = groupObj.children || [];
          groupObj.children.push(doc);
          docCollection[grpId] = groupObj;
        }
        return docCollection;
      }, {})
    );

    let filtered = docs;
    if (searchText) {
      filtered = docs.filter(
        (document) =>
          document.fileName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    }

    const getFilteredDocumentType = (documents) =>
      documents.filter(
        (document) => document.taggingData.documentType === documentTypeFilter
      );

    if (documentTypeFilter) {
      filtered = filtered.filter((document) => {
        if (
          documentTypeFilter === 'NONE' &&
          (!document.children || !document.children.length) &&
          !document.taggingData.documentType
        ) {
          return true;
        }
        if (document.children) {
          const filteredChildren = getFilteredDocumentType(document.children);
          if (filteredChildren.length) {
            document.children = filteredChildren;
            return true;
          }
        }
        return document.taggingData.documentType === documentTypeFilter;
      });
    }

    if(selectedDocumentsForModel === null || !isEqual(filtered, filteredDocuments)) {
      const allSuccessfullyTaggedDocuments = getAllSuccessfullyTaggedDocuments(filtered);
      setSuccessfulTaggedDocuments([...allSuccessfullyTaggedDocuments]);
      setSelectedDocumentsForModel([...allSuccessfullyTaggedDocuments]);
    }
    setFilteredDocuments(filtered);
  }, [documents, searchText, documentTypeFilter]);

  const getAllSuccessfullyTaggedDocuments = (filtered) => {
    return filtered.filter(document => {
      if(document.children && document.children.length >= 1) {
        const isAtleastOneSuccess = document.children.find(childDoc => childDoc.extractionJob && 
          childDoc.extractionJob.status === ExtractionJobStatus.SUCCESSFUL.key && 
          childDoc.isValidated
        );
        return isAtleastOneSuccess ? true : false;
      }
      return false;
    });
  };

  const onAddFileTutorial = useCallback(() => {
    store.dispatch(markFirstTourDone('projectPage'));
    setShowTour(false);
    setShowAddFileTutorial(true);
  }, []);

  const closeTours = useCallback(() => {
    setShowTour(false);
    setShowAddFileTutorial(false);
    setShowTaggingFormTutorial(false);
    store.dispatch(markFirstTourDone('projectPage'));
    store.dispatch(markFirstTourDone('documentPage'));
  }, []);

  const enableGenerateReport = documents.some(
    (document) =>
      document.extractionJob &&
      document.extractionJob.status === ExtractionJobStatus.SUCCESSFUL.key
  );

  const fileUploaded = useCallback(() => {
    setTourStep(2);
  }, []);

  const documentSelected = useCallback(
    (document) => {
      setSelectedDocument(document);
      !showTaggingFormTutorial && setTourStep(5);
    },
    [showTaggingFormTutorial]
  );

  const goToAddTaggingStep = () => {
    setShowAddFileTutorial((showAddFileTutorial) => {
      if (showAddFileTutorial) {
        setTourStep(1);
        setShowTaggingFormTutorial(true);
        return false;
      }
    });
  };

  const docType = useMemo(
    () =>
      selectedDocument
        ? DocumentsService.getDocumentType(selectedDocument)
        : false,
    [selectedDocument]
  );

  const onHelpClick = useCallback(() => {
    setShowTour(true);
    setShowTaggingForm(false);
  }, []);

  const onAddTagging = useCallback(
    (document) => {
      setSelectedDocument(document);
      setShowTaggingForm(true);
      window.dispatchEvent(new Event('resize'));
      store.dispatch(setCurrentActiveViewerSheet(null));
      setTourStep(1);
    },
    [project.uuid, store, selectedDocument]
  );

  const onDownloadCloseClick = useCallback(() => {
    store.dispatch(setIsConsolidatedModelDownloading(false));
  }, [store])


  return (
    <LoggedInUserPage>
      <div className="vertical-section">
        <ProjectPageHeader
          company={company}
          project={project}
          template={template}
          selectedModelForReportGeneration={selectedModelForReportGeneration}
          templateModels={templateModels}
          enableGenerateReport={enableGenerateReport}
          disableProjectCompletion={!enableProjectCompletion}
          onHelpClick={onHelpClick}
          onSearch={setSearchText}
          onFilterChange={setDocumentTypeFilter}
          projectExporters={projectExporters}
          documentsUuidsForModel={documentsUuidsForModel}
          completedModelDownloadEnabled={completedModelDownloadEnabled}
        />
        <ProjectPageSubHeader
          company={company}
          project={project}
          assetTypes={assetTypes}
          template={template}
          onFileUpload={fileUploaded}
          readOnlyAccess={readOnlyAccess}
          authService={authService}
          completedModelDownloadEnabled={completedModelDownloadEnabled}
        />
        {documents.length === 0 ? (
          <div style={{ flexGrow: 1 }} className="w-100">
            <DocumentFileDrop project={project} />
          </div>
        ) : (
          <DocumentsView
            documents={filteredDocuments}
            project={project}
            selectedDocument={selectedDocument}
            onAddTagging={onAddTagging}
            setSelectedDocument={setSelectedDocument}
            showTaggingForm={showTaggingForm}
            setShowTaggingForm={setShowTaggingForm}
            goToAddTaggingStep={goToAddTaggingStep}
            onDocumentSelect={documentSelected}
            setSelectedDocumentsForModel={setSelectedDocumentsForModel}
            selectedDocumentsForModel={selectedDocumentsForModel ? selectedDocumentsForModel : []}
            succesfulTaggedDocuments={succesfulTaggedDocuments}
          />
        )}
        <UploadProgress />
        {isConsolidatedModelDownloading &&
          <DownloadProgress
            fileName={downloadFileName}
            showDownloadProgress={isConsolidatedModelDownloading}
            onDownloadCloseClick={onDownloadCloseClick}
          />
        }
        <DocumentUploadErrorPopup />
      </div>
      <ProjectPageTour
        show={showTour}
        onClose={closeTours}
        onAddFileTutorial={onAddFileTutorial}
      />
      <AddFileTour
        onClose={closeTours}
        show={showAddFileTutorial}
        step={tourStep}
      />
      <DocumentPageTour
        show={showTaggingFormTutorial}
        step={tourStep}
        onClose={closeTours}
        showSplitting={FILE_EXT_TYPES.PDF === docType}
      />
      {project && project?.notes && <ProjectNote notes={project?.notes}/>}
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => {
  const authState = authSelector(state);
  const currentCompany = loggedInCompanySelector(state);
  const project = currentProjectSelector(state);
  const template = companyTemplatesSelector(state).find(
    (template) => template.uuid === project.templateUuid
  );
  const currentTemplate =
    companyTemplateModelSelector(state)[project.templateUuid];
  const readOnlyAccess = readOnlyAccessSelector(state);
  return {
    project,
    documents: currentProjectDocumentsAsListSelector(state),
    templates: companyTemplatesSelector(state),
    company: currentCompany,
    template,
    startTour: false,
    documentsSummary: documentsSummarySelector(state),
    templateModels: currentTemplate.TemplateModels
      ? currentTemplate.TemplateModels.filter(
          (template) => template.assetType === project.assetType && !template.isCompletedModel
        )
      : [],
    projectExporters: authState.company.companyProjectExporters || [],
    assetTypes: dynamicAssetTypesListSelector(state),
    isConsolidatedModelDownloading: isConsolidatedModelDownloadingSelector(state),
    downloadFileName: downloadFileNameSelector(state),
    readOnlyAccess
  };
};

export default connect(mapStateToProps)(
  withServices('projectsService', 'store', 'companyService', 'authService')(ProjectPage)
);
