import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { pick, unset, set } from 'lodash';
import { faBroom as clearTaggingIcon } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faEnvelope as needSupportIcon } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faTrash as deleteProjectIcon } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faPencilAlt as editProjectIcon } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faClone as cloneProjectIcon } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import DisplayAssetType from '../DisplayAssetType/DisplayAssetType';
import folderFile from '../../../assets/images/folder.svg';
import { PAGINATION_DEFAULT } from '../../../constants';
import IconButton from '../../core/Button/IconButton';
import { usDateFormat } from '../../../utils/utils';
import {
  documentsSummarySelector,
  dynamicAssetTypesAsObjSelector,
  loggedInCompanySelector,
  userAllowedTemplatesSelector,
} from '../../../store/selectors';
import style from './Projects.module.scss';
import CustomTableUI from './CustomTableUI';
import CustomTablePagination from './CustomTablePagination';
import {
  AutoCompleteModelUsedFilter,
  ProjectNameFilter,
  SelectAssetTypeFilter,
} from './CustomTableFilters';
import { userRoleSelector } from '../../../store/selectors';
import ProjectsPageSubHeader from './ProjectsPageSubHeader';
import ProjectZeroState from './ProjectZeroState';

const isClearTaggingDisabled = (documentsSummary) => {
  const { tagged } = documentsSummary;
  return tagged === 0;
};



function Projects({
  projects,
  companyService,
  projectsService,
  userService,
  loading,
  pageCount: controlledPageCount,
  fetchProjects,
  tileContainerClicked,
  queries,
  templates,
  allowedAssetTypes,
  onClearTagging,
  onDelete,
  onEdit,
  onOpen,
  onNeedSupport,
  onSelect,
  onAdd,
  assetTypes,
  documentsSummary,
  setHideTourButton,
  userRole,
  setProjects,
  currentCompany,
}) {
  const [propertyTypeFilter, setPropertyTypeFilter] = useState();
  const [clonedProject, setClonedProject] = useState({});
  const assetTypesOptions = useMemo(
    () =>
      Reflect.ownKeys(pick(assetTypes, allowedAssetTypes)).reduce(
        (assetTypeOption, propertyType) => {
          assetTypeOption[propertyType] = assetTypes[propertyType].label;
          return assetTypeOption;
        },
        {}
      ),
    [allowedAssetTypes]
  );
  const onClone = async (project) => {
    const clonedProject = await projectsService.cloneProject(project);
    setClonedProject(clonedProject);
  }

  const modelUsedOptions = useMemo(
    () =>
      Reflect.ownKeys(templates).reduce((modelUsed, templateUuid) => {
        const currentTemplate = templates[templateUuid];
        if (propertyTypeFilter && currentTemplate.assetTypes) {
          if (currentTemplate.assetTypes.includes(propertyTypeFilter)) {
            modelUsed[templateUuid] = currentTemplate.templateName;
          }
        } else {
          modelUsed[templateUuid] = currentTemplate.templateName;
        }
        return modelUsed;
      }, {}),
    [templates, propertyTypeFilter]
  );

  const disableClearTagging = useCallback(
    (project) =>
      isClearTaggingDisabled(documentsSummary[project.uuid] || {}),
    [templates, documentsSummary]
  );

  const isReadOnly = useMemo(() => {
    const templateWithWriteAccess = Reflect.ownKeys(templates).filter(key => templates[key].userTemplateAccess.writeAccess);
    if(templateWithWriteAccess.length > 0) {
      return false;
    }
    return true;
  }, [templates]);

  useEffect(() => {
    Promise.all([
      companyService.loadCompanyTemplates(),
      userService.loadUserAllowedTemplates(),
    ]);
  }, []);

  const setFilters = useCallback(
    (localValue, accessor) => {
      const newQuery = {
        ...queries,
        _offset: 0,
      };
      if (!localValue) {
        unset(newQuery, accessor);
      } else {
        set(newQuery, accessor, encodeURIComponent(localValue));
      }
      fetchProjects(newQuery);
    },
    [queries, fetchProjects]
  );

  const columns = useMemo(
    () => [
      {
        Header: '',
        width: '54px',
        disableFilters: true,
        disableSortBy: true,
        accessor: 'icon',
        Cell: () => (
          <div className={style.folderIcon}>
            <div>
              <img src={folderFile} width={20} alt="file icon" />
            </div>
          </div>
        ),
      },
      {
        Header: 'Property Name',
        accessor: 'name',
        width: '380px',
        canSort: true,
        Filter: (props) => (
          <ProjectNameFilter
            queries={queries}
            setFilters={setFilters}
            {...props}
          />
        ),
        Cell: ({ value }) => (
          <div title={value} className={style.propertyName}>
            {value}
          </div>
        ),
      },
      {
        Header: 'Asset Class',
        accessor: 'assetType',
        canSort: true,
        Filter: (props) => (
          <SelectAssetTypeFilter
            {...props}
            key="AssetTypeFilter"
            queries={queries}
            setFilters={setFilters}
            setPropertyTypeFilter={setPropertyTypeFilter}
            assetTypesOptions={assetTypesOptions}
          />
        ),
        Cell: ({ value }) => <DisplayAssetType AssetType={value} />,
      },
      {
        Header: 'Model Used',
        accessor: 'Template.templateName',
        canSort: true,
        Filter: (props) => (
          <AutoCompleteModelUsedFilter
            {...props}
            className="modelUsedFilter"
            key="AssetTypeFilter"
            queries={queries}
            fetchProjects={fetchProjects}
            modelUsedOptions={modelUsedOptions}
          />
        ),
        Cell: ({ row }) => row.original.TemplateMapping.templateName,
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        canSort: true,
        Cell: ({ value }) => usDateFormat(value),
      },
      {
        Header: 'Updated On',
        accessor: 'updatedAt',
        canSort: true,
        Cell: ({ value }) => usDateFormat(value),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row: { original: project } }) => (
          <>
            {userRole === 'SUPPORT_USER' && <IconButton
              title="Clone Project"
              className="smallIconButton cloneProjectIcon"
              icon={cloneProjectIcon}
              disabled={!project.TemplateMapping.UserTemplateAccesses[0].writeAccess}
              onClick={() => onClone(project)}
            />
            }
            <IconButton
              title="Edit"
              className="smallIconButton editProjectIcon"
              icon={editProjectIcon}
              disabled={!project.TemplateMapping.UserTemplateAccesses[0].writeAccess || !currentCompany.canEditProjectDetails}
              onClick={() => onEdit(project)}
            />
            <IconButton
              hidden
              title="Clear Tagging"
              className="smallIconButton clearTaggingIcon"
              icon={clearTaggingIcon}
              onClick={() => onClearTagging(project)}
              disabled={disableClearTagging(project) || !project.TemplateMapping.UserTemplateAccesses[0].writeAccess}
            />
            <IconButton
              title="Delete"
              className="smallIconButton deleteIcon"
              icon={deleteProjectIcon}
              onClick={() => onDelete(project)}
              disabled={!project.TemplateMapping.UserTemplateAccesses[0].writeAccess}
            />
            <IconButton
              title="Need support"
              className="smallIconButton needSupportIcon"
              icon={needSupportIcon}
              onClick={() => onNeedSupport(project)}
            />
          </>
        ),
      },
    ],
    [assetTypesOptions, modelUsedOptions, disableClearTagging, queries, currentCompany]
  );

  useEffect(() => {
    if (!queries._limit) {
      fetchProjects({ _limit: PAGINATION_DEFAULT.pageSize });
    }
  }, [queries]);

  const hasFilter = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const { _limit, _offset, ...rest } = queries;
    return Reflect.ownKeys(rest).length > 0 || _offset !== undefined
  }, [queries]);

  useEffect(() => {
    if (clonedProject && projects) {
      setProjects([clonedProject, ...projects]);
    }
  }, [clonedProject]);

  useEffect(() => {
    if (projects === null) {
      setHideTourButton(true);
    } else {
      setHideTourButton(!(projects?.length > 0 || hasFilter));
    }
  }, [projects, hasFilter]);

  if (projects === null) {
    return <div className='d-flex justify-content-center align-items-center h-100'>
      <Spinner as="span" animation="border" size="sm" className={style.spinner}/>
    </div>
  }

  return (
    <>
     {
      projects?.length > 0 || hasFilter ? (
        <>
          <div className="d-flex pr-3 py-2 position-relative">
            <ProjectsPageSubHeader style={style} onAdd={onAdd} isReadOnly={isReadOnly} 
              disableCreateProperty={currentCompany.isTrialOffering && userRole !="SUPPORT_USER"}  />
            <CustomTablePagination
              loading={loading}
              fetchProjects={fetchProjects}
              totalPageCount={controlledPageCount}
              queries={queries}
            />
          </div>
          <div className="position-relative flex-grow-1">
            <div
              onClick={tileContainerClicked}
              id="projectsContainer"
              className={`horizontal-section justify-content-start align-content-start flex-wrap fill-parent table-container list-view ${style.projectsViewScroll}`}
            >
              <CustomTableUI
                onOpen={onOpen}
                columns={columns}
                rows={projects}
                queries={queries}
                fetchProjects={fetchProjects}
              />
            </div>
          </div>
        </>
      ) : (
        <ProjectZeroState onAdd={onAdd} isReadOnly={isReadOnly} />
      )
     }
    </>
  );
}

const mapStateToProps = (state) => ({
  templates: userAllowedTemplatesSelector(state),
  documentsSummary: documentsSummarySelector(state),
  allowedAssetTypes: loggedInCompanySelector(state).assetTypes,
  currentCompany: loggedInCompanySelector(state),
  assetTypes: dynamicAssetTypesAsObjSelector(state),
  userRole: userRoleSelector(state)
});

export default connect(mapStateToProps)(
  withServices('userService', 'companyService', 'projectsService')(Projects)
);
