import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import UserFormComponent from './UserFormComponent';
import { USER_ROLES, PASSWORD_SCHEMA, CG_USER_ROLES } from '../../../constants';
import { companyTemplatesAsListSelector } from '../../../store/selectors';
import { getTemplateDropdownMap } from '../../../utils/utils';

const UserFormSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid first name.'),
    lastName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid last name.'),
    email: Yup.string().email('Please provide a valid email.').required('Please provide a valid email'),
  }),
  readAccessTemplate: Yup.array()
  .min(1, 'At least one email is required.')
  .required('At least one email is required.'),
});

function UserForm({
  onSubmitSuccess, bindSubmission, user, companyUUID, userService, companyTemplates
}) {
  const companyTemplatesMap = useMemo(() => getTemplateDropdownMap(companyTemplates),
  [companyTemplates]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllForWrite, setSelectAllForWrite] = useState(false);

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await userService.createCompanyUser(values, companyUUID);
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        ...user,
        readAccessTemplate: [],
        writeAccessTemplate: []
      }}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <UserFormComponent 
        {...formikProps} 
        formikProps={formikProps}
        bindSubmission={bindSubmission} 
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        companyTemplatesMap={companyTemplatesMap}
        setSelectAllForWrite={setSelectAllForWrite}
        selectAllForWrite={selectAllForWrite}
      />
      }
    />
  );
}

const mapStateToProps = state => ({
  companyTemplates: [
    ...companyTemplatesAsListSelector(state)
  ]
})

export default connect(mapStateToProps)(withServices('userService')(UserForm));
