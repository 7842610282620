import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import IconButton from '../../core/Button/IconButton';
import { getIsDocumentReExtractRequired } from '../../../utils/utils';
import { readOnlyAccessSelector } from '../../../store/selectors';
import {
  SpriteExtractedIcon,
  SpriteAddMoreTagsIcon,
  SpriteRenameIcon,
  SpriteClearTaggingIcon,
  SpriteDeleteIcon,
  SpriteNeedSupportIcon
} from '../../core/SpriteIcon/SpriteIcon';
import ProTag from '../ProTag/ProTag';

const ActionButton = React.forwardRef(({ children, onClick }, ref) => {
  const onActionButtonClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    },
    [onClick]
  );

  return (
    <div ref={ref} onClick={onActionButtonClicked}>
      {children}
    </div>
  );
});

function DocumentContextMenu({
  document,
  template,
  onRename,
  onNeedSupport,
  onDelete,
  onOpen,
  onClearTagging,
  onAddTagging,
  onReExtract,
  isChildDocument,
  hasTrialExhausted,
  readOnlyAccess
}) {
  const disableClearTagging = useMemo(() => {
    if (!!document.children) {
      return false;
    } else {
      return !document.taggingData || !document.taggingData.documentType;
    }
  }, [document]);

  const isReExtract = useCallback(
    (document) => getIsDocumentReExtractRequired(document),
    []
  );

  const openOrReExtractAction = useCallback((document) => {
    const action = isReExtract(document) ? onReExtract : onOpen;
    action(document);
  }, []);

  const onOpenOrReExtract = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openOrReExtractAction(document.children[0]);
    },
    [openOrReExtractAction, document]
  );

  const onOpenOrReExtractOld = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openOrReExtractAction(document);
    },
    [openOrReExtractAction, document]
  );

  const onAddTaggingClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onAddTagging();
    },
    [onAddTagging]
  );

  const onRenameClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onRename(document);
    },
    [onRename]
  );

  const onClearTaggingClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClearTagging();
    },
    [onClearTagging]
  );

  const onDeleteClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onDelete();
    },
    [onDelete]
  );

  const onNeedSupportClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onNeedSupport();
    },
    [onNeedSupport]
  );

  const canViewExtracted = useCallback(
    (document) => !!(document.children && document.children.length === 1),
    []
  );
  const canOldViewExtracted = useCallback(
    (document) =>
      !document.children &&
      !!(document.taggingData && document.taggingData.documentType),
    []
  );
  const isAddMoreTag = useMemo(
    () =>
      (document.taggingData && document.taggingData.documentType) ||
      document.children,
    [document]
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={ActionButton}>
        <IconButton
          variant="outline-secondary"
          className="smallIconButton actionButton"
          icon={faEllipsisV}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {canViewExtracted(document) && !isChildDocument && (
          <Dropdown.Item onClick={onOpenOrReExtract}>
            <SpriteExtractedIcon />{' '}
            {`${
              isReExtract(document.children[0])
                ? 'Re Extract'
                : 'View Extracted Data'
            }`}{' '}
          </Dropdown.Item>
        )}
        {(isChildDocument || canOldViewExtracted(document)) && (
          <Dropdown.Item onClick={onOpenOrReExtractOld}>
            <SpriteExtractedIcon />{' '}
            {`${isReExtract(document) ? 'Re Extract' : 'View Extracted Data'}`}{' '}
          </Dropdown.Item>
        )}
        {!isChildDocument && !readOnlyAccess && (
          <Dropdown.Item id="AddTagButton" onClick={onAddTaggingClicked}>
            <SpriteAddMoreTagsIcon />
            {isAddMoreTag ? 'Add More Tags' : 'Tag Document'}
          </Dropdown.Item>
        )}
        {!isChildDocument && !readOnlyAccess && (
          <Dropdown.Item onClick={onRenameClicked}>
            <SpriteRenameIcon />
            Rename
          </Dropdown.Item>
        )}
        {!isChildDocument && !readOnlyAccess && (
          <Dropdown.Item
            onClick={onClearTaggingClicked}
            disabled={disableClearTagging}
            className={disableClearTagging && 'text-secondary'}
          >
            <SpriteClearTaggingIcon />
            Clear Tagging
          </Dropdown.Item>
        )}
        {!readOnlyAccess && (      
        <Dropdown.Item onClick={onDeleteClicked}>
          <SpriteDeleteIcon />
          Delete
        </Dropdown.Item>)}
        <Dropdown.Item
          className="position-relative"
          onClick={onNeedSupportClicked}
          disabled={hasTrialExhausted}
        >
          <SpriteNeedSupportIcon />
          Need Support
          <ProTag className="needSupport" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state) => ({
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(DocumentContextMenu);
