import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import Checkbox from '../../core/Checkbox/Checkbox';
import FileUploader from '../FileUploader/FileUploader';
import { VALID_TEMPLATE_FILE_EXTENSION, VALID_DICTIONARY_FILE_EXTENSION, TEMPLATE_TAGS, VALID_SUMMMARY_SHEET_TOTAL_MAPPING_FILE_EXTENSION } from '../../../constants';
import ReactSelect from 'react-select';

const TemplateAssetTypeField = (props) => {
  const { onChange, assetTypes, ...rest } = props;
  const name = 'assetTypes';

  return (
    <>
      {Reflect.ownKeys(assetTypes).map((key) => {
        const propsValue = key;
        const { label } = assetTypes[key];
        return (
          <Field name={name} key={key}>
            {({ field, form }) => (
              <label>
                <input
                  className="ml-2 mr-1"
                  type="checkbox"
                  {...rest}
                  checked={field.value?.includes(propsValue)}
                  onChange={() => {
                    let v;
                    if (field.value?.includes(propsValue)) {
                      const nextValue = field.value?.filter(
                        (value) => value !== propsValue
                      );
                      form.setFieldValue(name, nextValue);
                      v = nextValue;
                    } else {
                      const nextValue = field.value?.concat(propsValue);
                      form.setFieldValue(name, nextValue);
                      v = nextValue;
                    }

                    if (onChange) {
                      onChange(v);
                    }
                  }}
                />
                {label}
              </label>
            )}
          </Field>
        );
      })}
      <FieldErrorMessage name={name} />
    </>
  );
};

function CompanyFormComponent({
  status,
  isSubmitting,
  bindSubmission,
  submitForm,
  templateFileName,
  selectedTemplateFile,
  onTemplateFileChange,
  onRemoveTemplateFile,
  dictionaryFileName,
  selectedDictionaryFile,
  onDictionaryFileChange,
  onRemoveDictionaryFile,
  assetTypes,
  keysToMask,
  isReportEnabled,
  allowAll,
  maskedTemplate,
  selectedCategorySequenceFile,
  selectedSummarySheetTotalConfigFile,
  onCategorySequenceFileChange,
  onCategorySequenceRemoveFile,
  onSummarySheetTotalConfigChange,
  onSummarySheetTotalConfigRemoveFile,
  onChangeReportEnabled,
  onChangeAllowAll,
  companyUsers = [],
  selectedUser,
  setSelectedUser,
  isGlobal,
  ...form
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <span style={{ whiteSpace: "pre-line" }}>{status.error}</span>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="templateName">Template Name</label>
          <Field id="templateName" className="form-control" name="templateName" type="text" />
          <FieldErrorMessage name="templateName" />
        </div>
        <div className="form-group">
          <label htmlFor="templateTag">Template Tag</label>
          <Field id="tag" component="select" className="form-control" name="tag">
            <option value={TEMPLATE_TAGS.SERVICING.key}>{TEMPLATE_TAGS.SERVICING.label}</option>
            <option value={TEMPLATE_TAGS.PRODUCTION.key}>{TEMPLATE_TAGS.PRODUCTION.label}</option>
            <option value={TEMPLATE_TAGS.ACQUISITION.key}>{TEMPLATE_TAGS.ACQUISITION.label}</option>
          </Field>
          <FieldErrorMessage name="tag" />
        </div>
        <div className="form-group">
          <TemplateAssetTypeField name="assetTypes" assetTypes={assetTypes} />
        </div>

        <div className="form-group">
          <label htmlFor="keysToMask">Keys to Mask</label>
          {
            Reflect.ownKeys(keysToMask).map(key => (
              <Checkbox name="keysToMask" className="ml-2 mr-1" value={keysToMask[key].key} key={keysToMask[key].key} label={keysToMask[key].label} />
            ))
          }
        </div>

        <div className="form-group">
          <label htmlFor="isReportEnabled">Report Enabled</label>
          <div className="row">
            <div className="radio-item col-md-auto">
              <input
                value
                name="isReportEnabled"
                type="radio"
                defaultChecked={isReportEnabled === true}
                onChange={() => onChangeReportEnabled(true)}
              />
              <label htmlFor="true">&nbsp; Yes</label>
            </div>

            <div className="radio-item col-md-auto">
              <input
                value={false}
                name="isReportEnabled"
                type="radio"
                defaultChecked={isReportEnabled === false}
                onChange={() => onChangeReportEnabled(false)}
              />
              <label htmlFor="false"> &nbsp; No</label>
            </div>
          </div>
        </div>

        {!isGlobal && <div className="form-group">
          <label htmlFor="allowAll">Allow all</label>
          <div className="row">
            <div className="radio-item col-md-auto">
              <input
                value
                name="allowAll"
                type="radio"
                defaultChecked={allowAll === true}
                onChange={() => onChangeAllowAll(true)}
              />
              <label htmlFor="true">&nbsp; Yes</label>
            </div>

            <div className="radio-item col-md-auto">
              <input
                value={false}
                name="allowAll"
                type="radio"
                defaultChecked={allowAll === false}
                onChange={() => onChangeAllowAll(false)}
              />
              <label htmlFor="false"> &nbsp; No</label>
            </div>
          </div>
        </div>}
        {!allowAll &&
          <div id="userField" className="form-group">
            <label htmlFor="selectedUser">Select user to give access</label>
            <ReactSelect
              placeholder="Select user..." 
              value={selectedUser} 
              name="selectedUser"
              isMulti
              options={companyUsers.map(user => ({ value: user.uuid, label: `${user.firstName} ${user.lastName}` }))}
              onChange={(user) => {
                const selectedUser = user ? user : [];
                setSelectedUser(selectedUser);
                form.setFieldValue('selectedUser', selectedUser?.value);
              }}
            />
            <FieldErrorMessage name="selectedUser" />
          </div>
        }


        <FileUploader
          onFileChange={onTemplateFileChange}
          validExtensions={VALID_TEMPLATE_FILE_EXTENSION}
          onRemoveFile={onRemoveTemplateFile}
          title="Mapping"
          selectedFile={selectedTemplateFile}
          fileName={templateFileName} />
        <FileUploader
          onFileChange={onDictionaryFileChange}
          validExtensions={VALID_DICTIONARY_FILE_EXTENSION}
          onRemoveFile={onRemoveDictionaryFile}
          title="Dictionary"
          selectedFile={selectedDictionaryFile}
          fileName={dictionaryFileName} />
        <FileUploader
          title="Category Sequence File"
          validExtensions={VALID_DICTIONARY_FILE_EXTENSION}
          selectedFile={selectedCategorySequenceFile}
          onFileChange={onCategorySequenceFileChange}
          onRemoveFile={onCategorySequenceRemoveFile} />
        <FileUploader
          title="Summary Sheet Total Mapping File"
          validExtensions={VALID_SUMMMARY_SHEET_TOTAL_MAPPING_FILE_EXTENSION}
          selectedFile={selectedSummarySheetTotalConfigFile}
          onFileChange={onSummarySheetTotalConfigChange}
          onRemoveFile={onSummarySheetTotalConfigRemoveFile} />
      </Form>
    </div>
  );
}

export default CompanyFormComponent;
