/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
import GC from '@grapecity/spread-sheets';
import { isNil } from 'lodash';
import {
  sheetIndex, sheetNames, spreadRangeChangedActionCode, spreadColorConfig, KEY_CODES, rrSheetIndex
} from '../constants';
import { checkChildKeyHasValue } from '../utils/utils';

const { targetSheetName } = sheetNames;
const {
 targetSheetIndex, workingSheetIndex
} = sheetIndex;
const { invalidCellColor } = spreadColorConfig;


class DocExtractionWorkbookManagerEvents {
  constructor(spreadsheet) {
    this.spreadsheet = spreadsheet;
    this.workbookManager = spreadsheet.workbookManager;

    this.handleCopyPasteEvents();
    this.handleRowColumnChangedEvent();
    this.handleDeleteRangeEvent();
    this.handleCellChangedEvent();
    this.handleCellEditModeEvent();
    this.handleDragFillEvent();
    this.hanldeShortCutKeyEvents();
  }

  handleCopyPasteEvents() {
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.ClipboardChanged, () => {
      const activeSheetIndex = this.workbookManager.getActiveSheetIndex();
      this.workbookManager.workbook.commandManager().execute({ cmd: 'onCopy', activeSheetIndex });
      this.spreadsheet.categoryCopiedValueRange();
      this.spreadsheet.subCategoryCopiedValueRange();
    });

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.ClipboardPasting, (_, args) => {
      const sheetIndex = this.workbookManager.getActiveSheetIndex();
      if (sheetIndex === targetSheetIndex || this.spreadsheet.getWorkingSheetIndex()) {
        const {
          cellRange: {
            row, col, rowCount, colCount
          }
        } = args;

        const fromRangeColumn = args?.fromRange?.col || -1;
        this.spreadsheet.fixedOSColumns.length = 0;
        this.spreadsheet.copiedHeaderColumnValues.length = 0;
        this.spreadsheet.isWorkbookHasOSTypeDoc() && this.spreadsheet.fixedOperatingStatementColumns();
        const defaultHeaderCellStyle = this.workbookManager.getCellStyle(targetSheetIndex, 0, 1);

        for (let i = 0; i < colCount; i++) {
          const columnIndex = col + i;
          const columnValue = this.workbookManager.getCellValue(sheetIndex, 0, columnIndex);
          const columnStyle = this.workbookManager.getCellStyle(sheetIndex, 0, columnIndex);
          const headerCellStyle = columnStyle || defaultHeaderCellStyle;
          this.spreadsheet.copiedHeaderColumnValues.push({ columnValue, columnStyle: headerCellStyle });
        }

        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head', { startCol: col });

        if (foundColumnIndex > 0 && fromRangeColumn > 0) {
          const previousHeadValues = this.workbookManager.getColumnValues(
            targetSheetIndex, row, rowCount, foundColumnIndex
          );
          setTimeout(() => this.workbookManager.workbook.commandManager().execute({
            cmd: 'updateCellColorOnPaste', sheetIndex, args, previousHeadValues, headIndex: foundColumnIndex, isUndo: false
          }), 0);
        }
      }
    });

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.ClipboardPasted, (_, args) => {
      const { sheet, cellRange: { row, col, colCount } } = args;
      const activeSheet = this.workbookManager.getActiveSheetIndex();
      if (
        row < 0 && 
        (activeSheet === targetSheetIndex || 
          activeSheet === 
          (this.spreadsheet.isWorkbookHasOSTypeDoc() ? workingSheetIndex : rrSheetIndex.workingSheetIndex)
        )) {
        if (this.spreadsheet.isColNotEmpty(1, 0)) {
          for (let i = 0; i < colCount; i++) {
            const columnIndex = col + i;
            this.workbookManager.workbook.commandManager().execute({
              cmd: 'onPaste', headerNames: this.spreadsheet.copiedHeaderColumnValues, activeSheet, args, isUndo: false
            });
            this.workbookManager.setCellValue(
              activeSheet, 0, columnIndex, this.spreadsheet.copiedHeaderColumnValues[i].columnValue
            );
          }
          this.spreadsheet.setCellFormatInUSNumber();
          this.spreadsheet.onRentRollEventTrigger();
          this.workbookManager.bindSheetHeader(sheet);
        }
      } else {
        this.spreadsheet.onRangePaste(activeSheet, args);
      }
    });
  }

  handleRowColumnChangedEvent() {
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.RowChanged, (_, args) => {
      const { sheetName, propertyName } = args;
      if (sheetName === targetSheetName) {
        this.spreadsheet.operatingStatementEvents();
        if (this.spreadsheet.isWorkbookHasRentRollTypeDoc() && propertyName === 'deleteRows') {
          setTimeout(() => this.spreadsheet.onRentRollEventTrigger(), 0);
        }
      }
    });

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.ColumnChanged, (_, args) => {
      const {
        sheetName, col, propertyName
      } = args;
      const amountColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Amount').foundColumnIndex;
      const adjustmentColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Adjustment').foundColumnIndex;
      const columnIndex = adjustmentColIndex !== -1 ? adjustmentColIndex : amountColIndex;
      if ((sheetName === targetSheetName && this.spreadsheet.isWorkbookHasRentRollTypeDoc()) && propertyName === 'deleteColumns') {
        setTimeout(() => this.spreadsheet.onRentRollEventTrigger(), 0);
      } if (sheetName === targetSheetName && propertyName === 'addColumns') {
        this.spreadsheet.setCellFormatInUSNumber();
        this.spreadsheet.operatingStatementEvents();
      } if (sheetName === targetSheetName && col >= columnIndex) {
        this.spreadsheet.operatingStatementEvents();
      }
    });
  }

  handleDeleteRangeEvent() {
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.RangeChanged, (_, args) => {
      const { sheet, sheetName, action, changedCells, row, col: startColumnIndex, colCount } = args;
      if (sheetName === targetSheetName && (!!changedCells.length && row > 0)) {
        if (action === spreadRangeChangedActionCode.clear) { // This code block will only execute on clear event
          this.workbookManager.suspend();
          const workbookData = this.workbookManager.getWorkbookData();
          const { columns } = workbookData.sheets[sheetName];
          for (let column = 0; column < colCount; column++) {
            const columnIndex = startColumnIndex + column;
            const columnHeaderName = sheet.getCell(0, columnIndex).value();
            if (columnHeaderName) {
              const foundColumnName = columns.filter(validCol => validCol)
              .find(column => column.headerName === columnHeaderName);
              if (foundColumnName && foundColumnName?.hasOwnProperty('dataType')) {
                if (foundColumnName.dataType !== 'string') { // ignoring valdation check on string type datatype
                  changedCells.forEach(({ row, col }) => {
                    if (columnIndex === col) {
                      const cellValue = sheet.getCell(row, col).value();
                      const cellStyle = sheet.getStyle(row, col) || {};
                      if (isNil(cellValue) && cellStyle?.hasOwnProperty('foreColor')) {
                        if (cellStyle.foreColor === invalidCellColor) {
                          this.spreadsheet.highlightCellValue(sheet, row, col, undefined);
                          this.spreadsheet.options.setWorkbookToast({ message: '', autohide: true });
                        }
                      }
                    }
                  });
                }
              }
            }
          }
          this.spreadsheet.onHighlightAmountCell(startColumnIndex); // Always call this method at last to highlight the diffrence value between amount & total     
          this.workbookManager.resume();
        }
      }
    });
  }

  handleCellChangedEvent() {
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.CellChanged, ({ type }, args) => {
      const {
        sheet, sheetName, col, row, newValue, oldValue
      } = args;
      if (sheetName === targetSheetName && oldValue !== newValue) {
        const workbookData = this.workbookManager.getWorkbookData();
        const { columns } = workbookData.sheets[targetSheetName];
        const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
        const categoryIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;

        if (this.spreadsheet.isWorkbookHasOSTypeDoc() || this.spreadsheet.isWorkbookHasOSFullTypeDoc()) {
          const { targetSheetData, sourceSheetData } = this.spreadsheet.getSheetData();
          const targetSheetColCount = this.workbookManager.getColumnCount(targetSheetData);
          const sourceSheetColCount = this.workbookManager.getColumnCount(sourceSheetData);

          if (col === headIndex) {
            this.spreadsheet.onTargetHeadValueChange(row, newValue, sourceSheetColCount, targetSheetColCount);
          } else if (col === 0) {
            this.spreadsheet.onTargetIdValueChange(newValue, sourceSheetColCount);
          } else if (col === categoryIndex) {
            const head = this.workbookManager.getCellValue(targetSheetIndex, row, headIndex);
            this.spreadsheet.onTargetCategoryValueChange(row, head, newValue);
          }

          this.spreadsheet.operatingStatementEvents();
        }

        if (this.spreadsheet.isWorkbookHasRentRollTypeDoc()) {
          if (checkChildKeyHasValue(this.spreadsheet.options.chargeCodeConfig, 'colIndex', col)) {
            this.spreadsheet.retrieveChargeCodes();
          }
          this.spreadsheet.onRentRollEventTrigger(type);
        }

        if (row !== 0) { // It runs on every cell change event
          if (this.spreadsheet.isWorkbookHasOSTypeDoc()) {
            this.spreadsheet.onHighlightAmountCell(col); // Always call this method at last to highlight the diffrence value between amount & total   
          }
          if (this.spreadsheet.isWorkbookHasOSFullTypeDoc()) {
            this.spreadsheet.getCFSummary();
          }
          if (this.spreadsheet.isWorkbookHasRentRollTypeDoc() || this.spreadsheet.isWorkbookHasOSTypeDoc()) {
            this.spreadsheet.validateCellOnValueChanged(sheet, row, col, columns, newValue); // Validating cell value on change
          }  
        }

        if (this.spreadsheet.isWorkbookHasOSFullTypeDoc()) {
          if (row === 1) { // Handles periodType dropdown
            this.spreadsheet.handlePeriodType(row, col, newValue);
          }
          if (row === 2) { // Handles period dropdown
            this.spreadsheet.bindPeriodColumns(row + 1, col, newValue);
          }
          if (row === 3) { // Handles period dates dropdown
            this.spreadsheet.handlePeriodDate(col, newValue);
          }
          if (row > 3) {
            this.spreadsheet.validateCellOnValueChanged(sheet, row, col, columns, newValue);
          }
        }
      }
    });
  }

  handleCellEditModeEvent() {
    let lastCellValue = '';
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.CellClick, (_, args) => {
      const {
        row, col, sheet, sheetName, sheetArea
      } = args;
      if (row === 0 && sheetName === targetSheetName && sheetArea > targetSheetIndex) {
        lastCellValue = this.workbookManager.getCellValue(targetSheetIndex, row, col);
        sheet.setActiveCell(row, col);
        sheet.startEdit(true);
      }
    });

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.EditStarting, (sender, args, e) => {
      this.spreadsheet.protectExtractedSheetHeader(args);
      this.spreadsheet.protectOSDropdowns(args);
      this.spreadsheet.protectRRDropdowns(args);
    })

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.EditEnding, (_, args) => {
      const {
        row, col, editingText, sheetName
      } = args;
      if (row === 0 && sheetName === targetSheetName) {
        const { foundColumnIndex } = this.workbookManager.customSearchColumnWithIgnoreCaseValue(
          targetSheetIndex, row, editingText
        );
        if (foundColumnIndex > -1 && lastCellValue !== editingText) {
          setTimeout(() => {
            this.workbookManager.setCellValue(targetSheetIndex, row, col, '');
            this.spreadsheet.options.setWorkbookToast({ type: 'danger', message: 'Column already exist in the sheet.' });
          }, 0);
        } else if (lastCellValue !== editingText) {
          this.spreadsheet.handleCustomChargeCodeColumn(editingText, col);
        }
      }
    });
  }

  handleDragFillEvent() {
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (_) => {
      if (this.spreadsheet.isWorkbookHasRentRollTypeDoc()) {
        this.spreadsheet.onRentRollEventTrigger();
      } else {
        this.spreadsheet.operatingStatementEvents();
      }
    });

    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.DragFillBlock, (...args) => {
      const [, {
        fillRange: {
          row, rowCount, col: selectedCol, colCount: selectedColCount
        }
      }] = args;

      const activeSheetIndex = this.workbookManager.getActiveSheetIndex();
      const headIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
      const categoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
      const subCategoriesColIndex = this.workbookManager.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
      const previousCategoryValues = this.workbookManager.getColumnValues(
        targetSheetIndex, row, rowCount, categoriesColIndex
      );
      const previousSubCategoryValues = this.workbookManager.getColumnValues(
        targetSheetIndex, row, rowCount, subCategoriesColIndex
      );
      const isCopyCategory = selectedColCount > 1;
      const isCopySubCategory = selectedColCount > 2;
      const copyCategoryValue = this.workbookManager.getColumnValues(
        targetSheetIndex, row - 1, 1, categoriesColIndex
      )[0];
      const copySubCategoryValue = this.workbookManager.getColumnValues(
        targetSheetIndex, row - 1, 1, subCategoriesColIndex
      )[0];
      const previousHeadValues = this.workbookManager.getColumnValues(targetSheetIndex, row, rowCount, headIndex);
      const { targetSheetData, sourceSheetData } = this.spreadsheet.getSheetData();
      const targetSheetColCount = this.workbookManager.getColumnCount(targetSheetData);
      const sourceSheetColCount = this.workbookManager.getColumnCount(sourceSheetData);
      const colCount = {
        targetSheetColCount,
        sourceSheetColCount
      };
      const undoState = {
        row,
        rowCount,
        selectedCol,
        isCopyCategory,
        selectedColCount,
        copyCategoryValue,
        isCopySubCategory,
        previousHeadValues,
        categoriesColIndex,
        copySubCategoryValue,
        previousCategoryValues,
        previousSubCategoryValues,
        categoryCol: categoriesColIndex,
        subCategoryCol: subCategoriesColIndex
      };

      setTimeout(() => this.workbookManager.workbook.commandManager().execute({
        cmd: 'onDragFill', isUndo: false, activeSheetIndex, undoState, colCount, headIndex
      }), 0);
    });
  }

  hanldeShortCutKeyEvents() {
    const { UNDO_KEY, DELETE_KEY, BACKSPACE_KEY } = KEY_CODES;
    this.workbookManager.workbookRef.current.addEventListener('keyup', (e) => {
      const activeSheetIndex = this.workbookManager.workbook.getActiveSheetIndex();
      const { keyCode } = e;
      const isProtectedSheet = this.workbookManager.workbook.sheets[activeSheetIndex].options.isProtected;
      if (!isProtectedSheet && activeSheetIndex === targetSheetIndex) {
        if (e.ctrlKey && keyCode === UNDO_KEY) { // Ctrl + Z for undo
          this.spreadsheet.operatingStatementEvents();
          this.spreadsheet.onRentRollEventTrigger();
        } else if (keyCode === BACKSPACE_KEY || keyCode === DELETE_KEY) { // Delete & Backspace
          this.spreadsheet.operatingStatementEvents();
          this.spreadsheet.onRentRollEventTrigger();
        }
      }
    });
  }

}

// eslint-disable-next-line import/prefer-default-export
export const handleWorkbookEvents = (props) => new DocExtractionWorkbookManagerEvents(props);