import * as moment from 'moment'
import qs from 'qs';
import { resetAuth, setAuth, setCgAuthToken, validateToken } from '../store/auth';
import { setAppMetaData } from '../store/metadata';
import { setDynamicAssetTypesList } from '../store/dynamicAssetType';
import { authSelector } from '../store/selectors';
import messages from '../../locales';
import { authConfig, appConfig, apiConfig, socialLogin } from '../config';
import { USER_ROLES } from '../constants';
import { ApplicationBootstraped, LoginSuccess, LogoutSuccess } from '../constants/eventTrackerMessage';
import { disableAllRoutes } from '../utils/utils';

const AUTH_TOKEN_KEY = `${appConfig.environment}-auth`;
const CG_AUTH_TOKEN_KEY = `${appConfig.environment}-cg-auth`;


class AuthService {
  constructor(store, eventTrackerService, toastrService) {
    this.store = store;
    this.eventTrackerService = eventTrackerService;
    this.toastrService = toastrService;
  }

  setRouter(router) {
    this.router = router;
  }

  setApiClient(apiClient) {
    this.apiClient = apiClient;
  }

  authState() {
    return authSelector(this.store.getState());
  }

  isLoggedIn() {
    const authState = this.authState();
    return authState && authState.token;
  }

  hasRole(role) {
    const authState = this.authState();
    return authState && authState.role === role;
  }

  isSuperAdmin() {
    const authState = this.authState();
    return authState.role === USER_ROLES.SUPER_ADMIN.key;
  }

  isSupportUser() {
    const authState = this.authState();
    return authState.role === USER_ROLES.SUPPORT_USER.key;
  }

  isAccountsExecutive() {
    const authState = this.authState();
    return authState.role === USER_ROLES.ACCOUNTS_EXECUTIVE.key;
  }

  async logoutFromOtherDevices(
    { email, password, captchaToken },
    redirect = true
  ) {
    try {
      await this.apiClient.post('/auth/revokeExistingToken', {
        email,
        password,
        captchaToken
      });
    } catch (error) {
      if (error.status === 401) {
        throw new Error(messages.errors.auth.invalidCredentials);
      }
      throw error;
    }
  }

  async logout() {
    try {
      await this.apiClient.post('/auth/revokeToken');
    } catch (e) {
      console.log(e);
    }

    try {
      await this.socialLogout();
    } catch (e) {
      console.log(e);
    }
    this.router.setRoute('loginPage');
    this.store.batchDispatch([resetAuth()]);
    this.eventTrackerService.track(LogoutSuccess);
    this.eventTrackerService.reset();
  }

  async refreshAuthState() {
    const {
      response: { user, company, eventCounts }
    } = await this.validateAuthToken();

    const auth = this.authState();
    const newAuthState = {
      user,
      token: auth.token,
      role: auth.role,
      company,
      eventCounts
    };
    localStorage.setItem(
      AUTH_TOKEN_KEY,
      JSON.stringify(newAuthState)
    )
    this.store.dispatch(validateToken(user, company, eventCounts));
    return newAuthState;
  }

  async socialLogout() {
    return new Promise((resolve, reject) => {
      window.gapi.load('auth2', () => {
        if (window.gapi.auth2) {
          const auth2 = window.gapi.auth2.getAuthInstance();
          if (auth2 && auth2.isSignedIn.get()) {
            auth2
              .signOut()
              .then(() => {
                resolve();
              })
              .catch(error => {
                console.error(error);
                reject(error);
              });
          } else {
            resolve();
          }
        }
      });
    });
  }
  async validateUser({ email, password, captchaToken }) {
    let isMultifactorAuthEnabled = false;
    try {
      const {response} = await this.apiClient.post('/auth/validateUser', {
        email,
        password, 
        captchaToken
      })
      isMultifactorAuthEnabled = response.isMultifactorAuthEnabled || false;
    } catch(error) {
      this.handleAuthError(error);
    }
    return isMultifactorAuthEnabled;
  }

  async login({ email, password, captchaToken }, redirect = true) {
    let response;
    try {
      response = await this.apiClient.post('/auth/token', {
        email,
        password,
        captchaToken
      });
    } catch (error) {
      this.handleAuthError(error);


      throw error;
    }

    const { user, role, token, company, eventCounts } = response.response;
    return this.handleAfterLoginActivities(user, role, token, company, eventCounts, redirect)
  }

  async getLoginUrl(requestUrl) {
    let response;
    try {
      response = await this.apiClient.post('/auth/getLoginUrl', {
        requestUrl
      });
    } catch (error) {
      this.handleAuthError(error);
      throw error;
    }

    return response;
  }

  handleAfterLoginActivities(user, role, token, company, eventCounts, redirect = true) {
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));
    this.eventTrackerService.track(LoginSuccess, {
      ...user,
      role,
      ...company
    });
    this.trackUser(user, role, company);

    if (redirect) {
      this.router.setRoute(this.resolveRoute());
    }

    return {
      user,
      role,
      token,
      company
    };

  }

  resetRoute() {
    this.router.setRoute(this.resolveRoute());
  }

  handleAuthError(error) {
    const { status, message } = error;
    if (status === 401) {
      throw new Error(messages.errors.auth.invalidCredentials);
    } else if (
      status === 403 &&
      messages.errors.auth.multipleSessions === message
    ) {
      throw new Error(messages.errors.auth.multipleSessionsNotAllowed);
    } else if (
      status === 403 &&
      messages.errors.auth.resetPassword === message
    ) {
      let queryParams = { ...error.error };
      if (error.error.email) {
        queryParams = {
          ...error.error,
          email: encodeURIComponent(error.error.email)
        };
      }
      this.router.setRoute('resetPasswordPage', queryParams);
      return;
    }
  }

  trackUser(user, role, company) {
    if (user && user.uuid) {
      this.eventTrackerService.identify(user.uuid);
      this.eventTrackerService.setPeople({ ...user, role });
      this.eventTrackerService.setCompany(company.name);
    }
  }

  async fetchUsersAuthState(queries) {
    let response;
    try {
      response = await this.apiClient.get('/auth/saml/fetchAuthDetail', {
        headers: { Authorization: `Bearer ${queries.token}` }
      });
    } catch (error) {
      const { status, message } = error;
      if (status === 401) {
        throw new Error(messages.errors.auth.invalidCredentials);
      } else if (
        status === 403 &&
        messages.errors.auth.multipleSessions === message
      ) {
        throw new Error(messages.errors.auth.multipleSessionsNotAllowed);
      } else if (
        status === 403 &&
        messages.errors.auth.resetPassword === message
      ) {
        let queryParams = { ...error.error };
        if (error.error.email) {
          queryParams = {
            ...error.error,
            email: encodeURIComponent(error.error.email)
          };
        }
        this.router.setRoute('resetPasswordPage', queryParams);
        return;
      }

      throw error;
    }

    const { user, role, token, company, eventCounts } = response.response;
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));

    this.router.setRoute('loginPage');

    return {
      user,
      role,
      token,
      company
    };
  }

  resolveRoute() {
    if(this.authState().user && !this.hasAgreedTerms) {
      return 'acceptTermsPage'
    }
    let routePage = 'projectsPage';
    if (this.isSuperAdmin()) {
      routePage = 'superAdminHomePage';
    } else if (this.isAccountsExecutive()) {
      routePage = 'accountsExecutivePage';
    }
    return routePage;
  }

  async changePassword({ oldPassword, newPassword }) {
    await this.apiClient.post('/auth/changePassword', {
      oldPassword,
      newPassword
    });
  }

  async validateAuthToken() {
    return this.apiClient.post('/auth/validateToken');
  }

  async requestResetPassword({ email, captchaToken }) {
    try {
      await this.apiClient.post('/auth/requestPasswordReset', {
        email,
        captchaToken
      });
    } catch (e) {
      if (e.status === 400) {
        throw new Error("We don't have this email registered with us");
      } else {
        throw e;
      }
    }
  }

  async resetPassword({ email, token, password }) {
    await this.apiClient.post('/auth/resetPassword', { email, token, password });
  }

  async resendVerifyEmail() {
    await this.apiClient.post('/auth/resendVerifyEmail');
  }

  async getAppMetaData() {
    const response = await this.apiClient.get('/metadata');
    return response.response;
  }

  async getAssetTypes() {
    const response = await this.apiClient.get('/assetTypes');
    return response.response;
  }

  async validatePayment(sessionId) {
    await this.apiClient.post('/payments/validatePayment', { clientSecret: sessionId });
    const {
      response: { user, company, eventCounts }
    } = await this.validateAuthToken();

    const auth = this.authState();
    localStorage.setItem(
      AUTH_TOKEN_KEY,
      JSON.stringify({
        user,
        token: auth.token,
        role: auth.role,
        company,
        eventCounts
      })
    )
    this.store.dispatch(validateToken(user, company, eventCounts));
    this.router.setRoute(this.resolveRoute());
  }

  registerSideEffects(sideEffect) {
    sideEffect.boot(async ({ dispatch }) => {
      const { user, token, role, company, eventCounts } = JSON.parse(
        localStorage.getItem(AUTH_TOKEN_KEY) || '{}'
      );
      this.eventTrackerService.track(ApplicationBootstraped);

      if (token) {
        try {
          await this.apiClient.post('/auth/validateAuthToken', { token });
          this.trackUser(user, role, company);
          dispatch(setAuth(user, token, role, company, eventCounts));
          dispatch(validateToken(user, company, eventCounts));
        } catch (e) {
          // Invalid auth token, don't set auth and trigger authenticated stuff
        }
      }

      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: socialLogin.googleClientId,
          cookiepolicy: 'single_host_origin'
        });
      });
      const assetTypes = await this.getAssetTypes();
      dispatch(setDynamicAssetTypesList(assetTypes.dynamicAssetTypes));
      const appMetaData = await this.getAppMetaData();
      dispatch(setAppMetaData(appMetaData));
    });

    sideEffect.after(
      setAuth,
      ([user, token, role, company, eventCounts], { dispatch }) => {
        localStorage.setItem(
          AUTH_TOKEN_KEY,
          JSON.stringify({
            user,
            token,
            role,
            company,
            eventCounts
          })
        );

        dispatch(validateToken(user, company, eventCounts));
      }
    );

    sideEffect.after(resetAuth, () => {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(CG_AUTH_TOKEN_KEY);
    });

    sideEffect.after(validateToken, async (_, { dispatch }) => {
      try {
        setTimeout(async () => {
          const { token } = JSON.parse(
            localStorage.getItem(AUTH_TOKEN_KEY) || '{}'
          );
          if (token) {
            const {
              response: { user, company, eventCounts }
            } = await this.validateAuthToken();
            dispatch(validateToken(user, company, eventCounts));
          }
        }, authConfig.timeout * 1000);
      } catch (e) {
        console.log(e);
        await this.logout();
      }
    });
  }

  async acceptTerms() {
    const response = await this.apiClient.post('/auth/acceptTerms');
    const user = response.response;
    const { token, role, company, eventCounts } = this.authState();
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));
    this.router.goBack();
  }

  async completeTrialUsersRegistration(companyDetails) {
    const response = await this.apiClient.post(
      '/auth/completeTrialUsersRegistration',
      companyDetails
    );
    const { user, company } = response.response;
    const { token, role, eventCounts } = this.authState();
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));
    this.router.goBack();
  }

  get hasAgreedTerms() {
    return (
      this.isSuperAdmin() ||
      this.isAccountsExecutive() ||
      this.authState().user.hasAgreedTerms
    );
  }

  get isSelfSignedAndSubscribed() {
    const { company: { isSelfSignup, subscriptionDetail } } = this.authState();
    return !(isSelfSignup && !subscriptionDetail);
  }

  get isEnterpriseCompany() {
    const { company: { isEnterpriseCompany } } = this.authState();
    return isEnterpriseCompany
  }

  get isTrialOffering() {
    const { company: { isTrialOffering } } = this.authState();
    return isTrialOffering
  }

  get isEnterpriseTrialUser() {
    const { company: { isTrialOffering, isSelfSignup } } = this.authState();
    return isTrialOffering && !isSelfSignup;
  }

  get isSelfSignup() {
    const { company: { isSelfSignup } } = this.authState();
    return isSelfSignup;
  }

  get isEmailVerified() {
    const { company: { isSelfSignup, isEnterpriseCompany }, user: { isEmailVerified } } = this.authState();
    return isEnterpriseCompany ? true : !(isSelfSignup && !isEmailVerified);
  }

  get isTrialDaysExpired() {
    const { company: { subscriptionDetail, createdAt, trialDays } } = this.authState();
    let trialDaysExpired = false;
    if (!subscriptionDetail) {
      trialDaysExpired = moment().isAfter(moment(createdAt).add(+trialDays, 'days'));
    }
    return trialDaysExpired;
  }

  async verifyEmail(email, verifyToken) {
    const response = await this.apiClient.post('/auth/verifyEmail', {
      email,
      token: verifyToken
    });

    const { user, role, token, company, eventCounts } = response.response;
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));
    return response;
  }

  async signup({ admin, company: companyValues }, redirect = true, noVerification = false) {
    let response;
    try {
      response = await this.apiClient.post('/auth/signup', {
        admin,
        company: companyValues,
        noVerification
      });
    } catch (error) {
      const { status } = error;
      if (status === 401) {
        throw new Error(messages.errors.auth.invalidCredentials);
      }
    }

    const { user, role, token, company, eventCounts } = response.response;
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));
    this.eventTrackerService.track(LoginSuccess, {
      ...user,
      role,
      ...company
    });
    this.trackUser(user, role, company);

    if (noVerification) {
      this.router.setRoute('projectsPage');
    }

    if (redirect) {
      this.router.setRoute('verifyEmail');
    }

    return {
      user,
      role,
      token,
      company
    };
  }

  checkHasTrialExpired() {
    const { company, role } = this.authState();
    const { pathname } = window.location;
    return disableAllRoutes(company, role) && pathname !== this.router.getUrl('projectsPage')
      ? { redirect: 'projectsPage' }
      : null
  }

  async socialLogin({ idToken, socialLoginType }, redirect = true) {
    let response;
    try {
      response = await this.apiClient.post('/auth/social-login', {
        idToken,
        socialLoginType
      });
    } catch (error) {
      console.log(error);
      this.toastrService.error(error.message);
      await this.socialLogout();
      throw error;
    }
    const { user, role, token, company, eventCounts } = response.response;
    this.store.dispatch(setAuth(user, token, role, company, eventCounts));

    if (redirect) {
      this.router.setRoute(this.resolveRoute());
    }

    return {
      user,
      role,
      token,
      company
    };
  }

  linkedInLogin() {
    const { linkedInClientId, linkedInRedirectUri } = socialLogin;
    /* eslint-disable max-len */
    const linkedInCallbackUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=r_liteprofile%20r_emailaddress`;
    window.open(linkedInCallbackUrl, '_self');
  }

  microsoftLogin() {
    const { microsoftClientId, microsoftRedirectUri } = socialLogin;
    const authorizationConfig = {
      client_id: microsoftClientId,
      response_type: 'code',
      redirect_uri: microsoftRedirectUri,
      scope: 'user.read',
    };
    const microsoftCallbackUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${qs.stringify(authorizationConfig)}`;
    window.open(microsoftCallbackUrl, '_self');
  }

  samlLogin(companySlug) {
    window.location = `${apiConfig.baseUrl}/auth/saml/${companySlug}`;
  }

  async sendVerifyEmailOTP({ email }) {
    return this.apiClient.post('/auth/sendVerifyEmailOTP', {
      email
    });
  }

  async verifyEmailOTP({ email, otp }) {
    return this.apiClient.post('/auth/verifyEmailOTP', {
      email,
      otp
    });
  }

  async getCGAuthUrl() {
   return this.apiClient.get('/auth/cgAuthUrl');
  }

  async getCGAdminAuthUrl() {
    return this.apiClient.get('/auth/cgAdminAuthUrl');
   }

  async validateCGAuthToken(cgToken) {
   const response = await this.apiClient.post('/auth/validateCGToken', {
      token: cgToken
    });

    const { auwUser : { firstName, lastName, email, uuid, isEmailVerified, 
        isActive, hasAgreedTerms, apiConfigAccess, cgUuid }, 
    company, token, eventCounts, role } = response.response;
    const user = {
      firstName,
      lastName,
      email,
      uuid,
      isEmailVerified,
      isActive,
      hasAgreedTerms,
      apiConfigAccess,
      cgUuid
    }
    return this.handleAfterLoginActivities(user, role, token, company, eventCounts, true)
  }

  async validateAdminCGAuthToken(cgToken) {
    const response = await this.apiClient.post('/auth/validateCGAdminToken', {
      token: cgToken
    });
    const { auwUser: { firstName, lastName, email, uuid, isEmailVerified, isActive, hasAgreedTerms },
      token, cgAuthToken, eventCounts, role } = response.response;

    this.store.dispatch(setCgAuthToken(cgAuthToken));

    localStorage.setItem(
      CG_AUTH_TOKEN_KEY,
      JSON.stringify({
        cgAuthToken
      })
    );

    const user = {
      firstName,
      lastName,
      email,
      uuid,
      isEmailVerified,
      isActive,
      hasAgreedTerms
    }
    return this.handleAfterLoginActivities(user, role, token, {}, eventCounts, true)
  }
}

export default AuthService;
