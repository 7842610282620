import { saveAs } from 'file-saver';
import { get, isEmpty, isUndefined } from 'lodash';
import {
  addProject,
  setProjects,
  updateProject,
  deleteProject,
  setDocumentsSummary
} from '../store/projects';
import {
  setCurrentProject,
  setProjectDocuments,
  setIsDocumentValidated,
  setReports,
  setTemplateHeadCategories,
  setCategorySequence,
  setTemplateMappings,
  setAssumption,
  setProjectSpecificAssumption,
  setIsDocumentTaggingActive,
  setCanDownloadConsolidatedModel,
  setSummarySheetTotalConfig,
  setIsConsolidatedModelDownloading,
  setDownloadFileName,
  setReadOnlyAccess
} from '../store/currentProject';
import Service from './Service';
import { constructProjectXlsxAndModelName } from '../utils/utils';
import { ExtractionJobStatus } from '../constants';
import {
  ProjectCreated,
  ProjectDeleted,
  ProjectUpdated
} from '../constants/eventTrackerMessage';
import * as JSZip from 'jszip';

const processProjects = (response) => response.rows;

class ProjectsService extends Service {
  constructor(apiClient, store, eventTrackerService, toastrService) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
    this.eventTrackerService = eventTrackerService;
    this.toastrService = toastrService;
    this.modelNameRestrictedStrings = {
      MULTIFAMILY: 'MF',
      RETAIL: 'RTL',
      HOTEL: 'HTL'
    };
  }

  async loadCurrentProject(projectUuid) {
    const result = await this.apiClient.get(`/projects/${projectUuid}`);
    const userTemplateAccess = result.response.project.userTemplateAccess;
    this.store.dispatch(setReadOnlyAccess(!userTemplateAccess.writeAccess))
    this.store.dispatch(updateProject(result.response.project));
    this.store.dispatch(setCurrentProject(result.response.project));
  }

  async getPublicProjectDetails(reportUuid, projectUuid) {
    const result = await this.apiClient.get(
      `/shared/reports/${reportUuid}/projects/${projectUuid}`
    );
    return result.response.project;
  }

  async getPublicDocumentsList(reportUuid, projectUuid) {
    const result = await this.apiClient.get(
      `/shared/reports/${reportUuid}/projects/${projectUuid}/documents`
    );

    return result.response.documents;
  }

  async loadProjectReport(uuid, isOpen) {
    let result;
    if (isOpen) {
      result = await this.apiClient.get(`/shared/reports/${uuid}/report`);
    } else {
      result = await this.apiClient.get(`/projects/${uuid}/report`);
    }

    this.store.dispatch(setReports(result.response.reports));
  }

  async getReportLinkDetails(reportUuid) {
    const result = await this.apiClient.get(`/shared/report/${reportUuid}`);
    return result.response.data;
  }

  async createReportLink(projectUuid) {
    const result = await this.apiClient.post(
      `/projects/${projectUuid}/reportLink`
    );
    return result.response;
  }

  async updateReportLink({ projectUuid, uuid }, reportLinkDetails) {
    const result = await this.apiClient.put(
      `/projects/${projectUuid}/reportLink/${uuid}`,
      reportLinkDetails
    );
    return result.response;
  }

  async loadCurrentProjectDocuments(projectUuid) {
    const result = await this.apiClient.get(
      `/projects/${projectUuid}/documents`
    );
    const { documents } = result.response;
    const documentNotValidated = await documents.find(doc => (!doc.isValidated && !isEmpty(doc.taggingData)) && !(doc?.extractionJob.status === "FAILED"));
    const isTaggingPending = documents
      .map((doc) => get(doc, 'extractionJob.status'))
      .some((status) => status === ExtractionJobStatus.ACTIVE.key);

    this.store.batchDispatch([
      setProjectDocuments(documents),
      setIsDocumentTaggingActive(isTaggingPending),
      setIsDocumentValidated(isUndefined(documentNotValidated) ? true : false)
    ]);
    return result;
  }

  async upsertProject(project, values) {
    if (project.uuid) {
      return this.updateProject(project.uuid, values);
    }
    return this.createProject(values);
  }

  async deleteProject(project) {
    const result = await this.apiClient.delete(`/projects/${project.uuid}`);
    this.eventTrackerService.track(ProjectDeleted, {
      ...result.response.project
    });
    this.store.dispatch(deleteProject(result.response.project.uuid));
  }

  async cloneProject(project) {
    try{
      const result = await this.apiClient.post(`/projects/clone/${project.uuid}`);
      this.toastrService.success('Project cloned sucessfully');
      return result.response.project;
    } catch (e) {
      console.error(e);
      this.toastrService.error('Unable to clone the project!');
    }
  }

  async clearTagging(project) {
    await this.apiClient.post(`/projects/${project.uuid}/clearProjectTagging`);
    await this.apiClient.put(`/projects/${project.uuid}/clearProjectReport`);
    await this.loadCurrentProjectDocuments(project.uuid);
    await this.getProjectDocumentSummary(project);
    await this.loadCurrentProject(project.uuid);
  }

  async createProject(values) {
    const result = await this.apiClient.post('/projects', {
      project: values
    });
    this.eventTrackerService.track(ProjectCreated, {
      ...result.response.project
    });
    this.store.dispatch(addProject(result.response.project));
    return result.response.project;
  }

  async getProjectDocumentSummary(project) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/documentsSummary`
    );
    await this.getConsolidatedModelDownloadAccessStatus(project.uuid);
    this.store.dispatch(
      setDocumentsSummary(project, result.response.documentsSummary)
    );
  }

  async updateProject(uuid, values) {
    const result = await this.apiClient.put(`/projects/${uuid}`, {
      project: values
    });
    await this.getConsolidatedModelDownloadAccessStatus(uuid);
    await this.loadCurrentProjectDocuments(result.response.project.uuid);
    await this.getProjectDocumentSummary(result.response.project);
    this.eventTrackerService.track(ProjectUpdated, {
      ...result.response.project
    });
    this.store.dispatch(updateProject(result.response.project));
    this.store.dispatch(setCurrentProject(result.response.project));

    return result.response.project;
  }

  async updateProjectByAdmin(company, project, values) {
    const result = await this.apiClient.put(
      `/admin/companies/${company.uuid}/projects/${project.uuid}`,
      {
        project: values
      }
    );
    this.store.dispatch(updateProject(result.response.project));

    return result.response.project;
  }

  async fetchAddress(project) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/address`
    );

    return result.response.address;
  }

  async getConsolidatedModelDownloadAccessStatus(uuid) {
    try {
      const result = await this.apiClient.get(
        `/projects/${uuid}/consolidatedModelDownloadAccess`
      );
      this.store.dispatch(setCanDownloadConsolidatedModel(result.response.canDownloadConsolidatedModel));
    } catch (e) {
      console.error(e);
    }
  }

  async markProjectCompletion(project, status) {
    const result = await this.apiClient.put(
      `/projects/${project.uuid}/status?isCompleted=${status}`
    );
    this.store.dispatch(updateProject(result.response.project));
    this.store.dispatch(setCurrentProject(result.response.project));
  }

  registerSideEffects(sideEffect) {
    sideEffect.after(updateProject, ([project]) => {
      this.getProjectDocumentSummary(project).catch(console.log);
    });
  }

  async loadProjectTemplateHeadCategories(project) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/templateCategories`
    );
    this.store.dispatch(
      setTemplateHeadCategories(result.response.headCategories)
    );
    this.store.dispatch(
      setSummarySheetTotalConfig(result.response.summarySheetTotalConfig)
    );
    this.store.dispatch(setCategorySequence(result.response.categorySequence));
    this.store.dispatch(setTemplateMappings(result.response.templateMappings));
  }

  async downloadXlsxForModelIntegration(project, selectedDocumentsForModel) {
    try {
      const response = await this.apiClient.post(
        `/projects/${project.uuid}/modelIntegrationXlsx`,
        { documents: selectedDocumentsForModel },
        { responseType: 'blob' }
      );
      const xlsxName = constructProjectXlsxAndModelName(
        project,
        this.modelNameRestrictedStrings
      );
      saveAs(response, `${xlsxName}.xlsx`);
    } catch (e) {
      console.error(e);
      this.toastrService.error('Something went wrong!');
    }
  }

  async configureApiIntegration(projectUuid, integrationConfigrationUuid, selectedDocuments, frequency = null, userDefType = null) {
    const result = await this.apiClient.post(`/projects/${projectUuid}/apiIntegration/${integrationConfigrationUuid}`,
      { documents: selectedDocuments,
        frequency,
        userDefType
      }
    );
    return result;
  }

  async downloadProjectModel(project) {
    const response = await this.apiClient.get(
      `/projects/${project.uuid}/models`,
      { responseType: 'blob' }
    );
    const modelName = constructProjectXlsxAndModelName(
      project,
      this.modelNameRestrictedStrings
    );
    saveAs(response, `${modelName}.xlsm`);
  }

  async getCompletedModel(project) {
    const response = await this.apiClient.get(
      `/projects/${project.uuid}/completedModel`,
      { responseType: 'blob' }, true
    );
    saveAs(response.data, response.headers['content-disposition'].split('filename=')[1]);
  }

  async deleteCompletedProjectModel(project) {
    await this.apiClient.delete(
      `/projects/${project.uuid}/completedModel`
    );
  }

  async downloadProjectNormalizedJson(project, selectedDocumentsForModel) {
    const response = await this.apiClient.post(
      `/projects/${project.uuid}/normalizedJson`,
      { documents: selectedDocumentsForModel },
    );
    const normalizedJson = JSON.stringify(response.response);
    const blob = new Blob([normalizedJson], { type: 'application/json' });
    const jsonName = constructProjectXlsxAndModelName(
      project,
      this.modelNameRestrictedStrings
    );

    saveAs(blob, `${jsonName}_${Date.now()}.json`);
  }

  async sendProjectNormalizedJsonToFTPServer(project, selectedDocumentsForModel) {
    const fileName = constructProjectXlsxAndModelName(
      project,
      this.modelNameRestrictedStrings
    );
    const response = await this.apiClient.post(
      `/projects/${project.uuid}/normalizedJson/ftp`,
      { documents: selectedDocumentsForModel, fileName: `${fileName}_${Date.now()}` },
    );

    this.toastrService.success(response.message)
    return response.response;
  }

  async exportProjectInFormat(project, exporter) {
    const getNormalizedJson = this.apiClient.get(
      `/projects/${project.uuid}/normalizedJson`
    );
    const getExporterConfig = this.apiClient.get(
      `/projects/projectExporters/${exporter.uuid}/config`
    );
    const [normalizedJson, configRes] = await Promise.all([
      getNormalizedJson,
      getExporterConfig
    ]);
    const exporterConfig = { ...configRes.response };
    const xmlName = constructProjectXlsxAndModelName(
      project,
      this.modelNameRestrictedStrings
    );
    const options = { assetType: project.assetType };
    // eslint-disable-next-line no-unused-vars
    let __xx;
    const mapFunc = eval(`__xx =${exporterConfig.mappingFunction.functionKey}`);

    const outputXML = mapFunc(
      normalizedJson.response,
      options,
      exporterConfig.mapper
    );
    const xmlBlob = new Blob([outputXML], { type: 'text/plain' });

    saveAs(xmlBlob, `${xmlName}.xml`);
  }

  async updateProjectAssumption(project, assumption) {
    const result = await this.apiClient.post(
      `/projects/${project.uuid}/assumption`,
      assumption
    );

    const responseAssumption = result.response.assumption;
    this.store.dispatch(setAssumption(responseAssumption));
    return responseAssumption;
  }

  async getCompanyProjects(companyUuid) {
    const options = {
      queryParams: {
        companyUuid
      }
    };
    const endPoint = '/admin/projects';
    const maxRowCountPerPage = 1000;
    await this.fetchList(
      endPoint,
      maxRowCountPerPage,
      setProjects,
      processProjects,
      null,
      options
    );
  }

  async getProjects() {
    const endPoint = '/projects';
    const maxRowCountPerPage = 500;
    await this.fetchList(
      endPoint,
      maxRowCountPerPage,
      setProjects,
      processProjects,
      1000
    );
  }

  async downloadProjectCSV(endPoint, options, fileName = 'Projects.csv') {
    const response = await this.apiClient.get(endPoint, {
      ...options,
      responseType: 'blob'
    });
    saveAs(response, fileName);
  }

  async sendProjectCSV(endPoint, options) {
    await this.apiClient.get(endPoint, { ...options });
    this.toastrService.success('Email sent successfully')
  }

  async fetchAssumption({ uuid }) {
    const endPoint = `/projects/${uuid}/assumptions`;
    const { assumptions } = (await this.apiClient.get(endPoint))['response'];
    this.store.dispatch(setAssumption(assumptions));
  }

  async fetchProjectSpecificAssumption({ uuid }) {
    const endPoint = `/projects/${uuid}/assumptions`;
    const { specificAssumptions } = (await this.apiClient.get(endPoint))[
      'response'
    ];
    this.store.dispatch(setProjectSpecificAssumption(specificAssumptions));
  }

  async onDownloadConsolidatedModel(project, company, selectedDocumentsForModel) {
    this.store.dispatch(setIsConsolidatedModelDownloading(true));
    this.store.dispatch(setDownloadFileName(`${company.name}_${project.name}.xlsm`));
    const response = await this.apiClient.post(
      `/projects/${project.uuid}/modelWithXlsxData`,
      { documents: selectedDocumentsForModel },
      { responseType: 'blob' }
    );
    this.store.dispatch(setIsConsolidatedModelDownloading(false));
    this.store.dispatch(setDownloadFileName(''));
    saveAs(response, `${company.name}_${project.name}.xlsm`);
  }


  async migrateProjects(payload) {
    const { companyUuid, file, templateTag, sendLogTo} = payload;
    const formData = new FormData();
    formData.append('companyUuid', companyUuid);
    formData.append('file', file);
    formData.append('templateTag', templateTag);
    formData.append('sendLogTo', sendLogTo);
    
    const url = `/admin/migrateProjects`;
    const result = await this.apiClient.post(
      url,
      formData,
    ).catch((e) => {
      throw e;
    });
    
    return result
  }
  async bulkProjectsJson(payload) {
    try {
      const response = await this.apiClient.post('/admin/bulkNormalizedJson', {
        ...payload,
      },
      {
        responseType: 'blob'
      });
  
      const zipData = response;
      try {
        const zip = await JSZip.loadAsync(zipData);
        //ZIP file is valid!, If no errors occur
      } catch (err) {
        console.error('Error validating ZIP file:', err);
      }
      const filename = "project_data.zip";
      saveAs(zipData, filename);
    } catch (err) {
      console.error(err);
    }
  }

  async bulkUpdateProjects(payload) {
    const { companyUuid, file, sendLogTo} = payload;
    const formData = new FormData();
    formData.append('companyUuid', companyUuid);
    formData.append('file', file);
    formData.append('sendLogTo', sendLogTo);
    return await this.apiClient.put('/admin/bulkUpdateProjects', formData);
  }

}

export default ProjectsService;
